
/* .container-fp {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .bottom-left-image {
    width: auto;
    height: auto;
    max-width: 50%;
    max-height: 50%;
    margin: 20px; optional margin from the bottom and left
  }
   */

   @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
   .container-fp {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .form-modal {
   

padding: 64px 41px 13px 41px;
gap: 20px;
border-radius: 15px ;
border: 1px ;


    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    
    box-shadow: 0px 2px 4px 0px #151D280A;

    width: 350px;
    text-align: center;
    /* width: 432px; Adjust the width as needed */
  }

  .form-modal-tost{
   padding: 10px;
gap: 20px;

border: 1px ;


    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    
    box-shadow: 0px 2px 4px 0px #151D280A;
    width:406px;
    border: 1.5px solid #1A0DAB;
    text-align: center;
    z-index: 1;
  }

.tost-cntr{
  display:flex;
}
.tost-tick{
  width: 24px;
  height:24px;
  padding:10px;
}
.tost-txt{
  font-family: 'Inter';
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;
padding:10px;

}

  .form-modal-cnt{
    display:flex;
    flex-direction: column;
    gap: 17px;
    box-sizing: border-box;
  }

  .form-title-subtitle{
    display:flex;
    flex-direction: column;
  }
  
  .form-modal-title {
    
font-family: 'Inter';
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;

  }
  
  .form-modal-sub-tittle{
    
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;

    color:  #B5B5B5;
    
    
  }
.form-model-email-submit{
    display: flex;
    flex-direction: column;
    gap:20px;
}

  .form-modal-email-input{
    display: flex;
    flex-direction: column;
    gap:16px;
    box-sizing: border-box;
  }
  
  .form-modal label {
    display: block;
   
    text-align: left;
   
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 19.99px;
text-align: left;

  }
  .required-star{
    color: red;
  }
  
  .form-modal input {
    box-sizing: border-box;

   
padding: 9.5px 13px 9.5px 13px;

border-radius: 6px ;
border: 1px ;


    width: 330px;
 
   
    border: 1px solid #DEE4ED;
    border-radius: 4px;
  }
  
  .reset-button {
    align-self: center;
    width: 141px;
    background: #003CFF;

    /* background-color: #007BFF; Primary color */
    color: white;
    
    border: none;
    border-radius: 4px;
    cursor: pointer;
    
padding: 10px 45px 10px 45px;
gap: 10px;
border-radius: 3px ;


  }
  
  .reset-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .remember-me {
   
font-family: 'Inter';
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;

    margin: 20px 0px 0px 0px ;
  }
  
  .bottom-left-image {
    width: auto;
    height: auto;
    max-width: 50%;
    max-height: 50%;
    /* margin: 20px; optional margin from the bottom and left */
  }
  

  @media screen and (max-width:768px) {

    .container-fp{

      display: none;
    }
    
    .mobile-screen-fp {
      gap:50px;
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      background-color: white;
     }
    
     .form-modal-m{
      width: auto;
      position: relative;
      top:0;
      /* margin: auto; */
     }

     .form-modal{
      position: relative;
      margin: auto;
      left: 0;
      transform: none;
      display: flex;
      flex-direction: column;
      top:0;
      width: auto;
      margin: auto;
     }

     .form-modal-tost{
      width: auto;
        position: relative;
        top: 0;
     }

     .form-modal input{
      width: auto;
     }
  }

  @media screen and (min-width:768px){
    
    .mobile-screen-fp{
      display:none
    }
    .mobile-navbar{
      display: none;
    }
  }