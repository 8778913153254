.edit-profile {
  width: 548px; /* Original width for desktop */
  padding: 0px 10px 24px 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-height: 600px;
  overflow-y: auto;
  margin: 0 auto; /* Center the container on the page */
}

/* For tablet screens (768px and below) */
@media (max-width: 768px) {
  .edit-profile {
    width: 80%; /* Shrinks to 80% of the screen width */
  }
}

/* For mobile screens (480px and below) */
@media (max-width: 480px) {
  .edit-profile {
    width: 90%; /* Shrinks further for smaller screens */
    padding: 0px 5px 24px 5px; /* Adjust padding to reduce space */
  }
}

  
  .edit-profile  {
    text-align: left;
    
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .form-group {
    flex: 1;
    margin-bottom: 15px;
  }
  
  .form-group  {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input, 
  .form-group select,
  .form-group textarea {
    width: 100%;
    height:40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
  }
  .select-input{
    width: 100%;
    height:40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
  }

  .edit-input{
    width: 100%;
    height:40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  .form-group textarea {
    resize: none;
    height: 60px;
  }
  
  .form-group.file-input input[type="file"] {
    display: inline-block;
    margin-top: 10px;
    height:73px;
  }
  
  .form-group.file-input span {
    display: block;
    font-size: 12px;
    color: #666;
    margin-top: 5px;
  }

  
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px; /* Reduce the gap between the buttons */
  }
  
  .cancel-btn,
  .save-btn {
    display: flex;
    justify-content: center; /* Center the text horizontally */
    align-items: center; /* Center the text vertically */
    padding: 0; /* Remove padding since width and height are set */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-btn {
    background-color: rgba(224, 224, 224, 1);
    color: #333;
    width: 75px;
    height: 39px;
  }
  
  .save-btn {
    background-color: rgba(45, 129, 229, 1);
    color: #fff;
    width: 61px;
    height: 39px;
    margin-left: 0; /* Remove the automatic margin to align it with the Cancel button */
  }
  
  .save-btn:hover {
    background-color: #0056b3;
  }
  
  .form-group label {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .form-group input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .dropdown-checkbox {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-btn {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    //min-width: 100%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
  }
  
  .dropdown-checkbox:hover .dropdown-content {
    display: block;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    //gap: 10px;
    margin-bottom: 5px;
  }
  
  .checkbox-label input[type="checkbox"] {
    margin-right: 10px;
  }