@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

.pricing{

  /* display: flex;
  flex-direction: column; */
  width:100vw;
  height: 100vh;
  margin:0;
  padding: 0;
  box-sizing: border-box;

}



.pricing-cntr{
  
 
 display: flex;
 flex-direction: column;
 align-content: center;
 align-items: center;
 justify-content: center;


}


.price-heading {
  /* width: 598px;
  height: auto; Adjusted to fit content height */
  /* padding: 0px 0px 3px 0px; */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  margin: auto; /* Center horizontally */
  gap: 10px; 
  margin-top: 123px;
  margin-bottom: 44px;
}

.price-heading-main {
  font-size: 40px;
  font-weight: 900;
  line-height: 48.41px;
  text-align: center;
  color: rgba(35, 29, 79, 1);

  p{
    margin:0;
    padding:0;
  }
}

.price-heading-submain {
  /* width: 348.93px;
  height: 24px; */
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: rgba(132, 129, 153, 1);
  text-align: center;
 
  p{
    margin: 0;
  }
}

/* Price container with two cards */

.pricing-cnt1{

  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px;
}


  .pricing-cards-container {
    display: flex;
    width:80%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 68px;
    /* padding: 20px; */

  }
  
  .card-p {
    width: 29.3%;
    height: 92.3vh;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    padding: 15px;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
  }


  
  .standard-card {
    height: auto;
    margin-top: 40px;
    border: 3px solid #B4B4B4
    

  }
  .outer-pluscard{
    width: 32.5%;
   
    display: flex;
    flex-direction: column;

    /* position: relative; */
    box-sizing: border-box;
  }
  
  .plus-card {
    box-sizing: border-box;
    width:100%;
    height:100%;
    position: relative;
    border: 2px solid #1A0DAB;
    box-shadow: 0 4px 12px rgba(74, 0, 224, 0.2);
  }
  

  .card-title-desc{
    display:flex;
    flex-direction: column;
  }


  .card-title-p {
    font-family: Inter;
    font-size: 28px;
    font-weight: 900;
    line-height: 33.89px;
    text-align: left;
    
  }
  
  .card-description-p {
   
    font-family: Inter;
font-size: 13.5px;
font-weight: 500;
line-height: 16.34px;
text-align: left;

  }
  
  .card-price {
    font-family: Inter;
    font-size: 36px;
    font-weight: 400;
    line-height: 46px;
    text-align: left;
    
    margin-top: 25px;
  }
  
  .card-subprice {
    font-size: 16px;
    color: #666;
   
  }
  
  .features-list-p {
    
    list-style: none;
    padding: 0;
    gap:10px;
    margin-top: 25px;
    margin-bottom: 0;
    display:flex;
    flex-direction: column;
  }
  
  .feature-item-p {
    gap:10px;
    display:flex;
    font-family: Inter;
font-size: 15px;
font-weight: 500;
line-height: 18.15px;
text-align: left;
align-items: center;
 img{
  width:20px;
  height: 20px;
  object-fit: contain;
  
 }


  }
  
  .inactive-feature {
    color: red;
  }
  
  .btn-p {
    display: flex;
    background-color:#1A0DAB;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 70%;
    font-size: 16px;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin:  25px auto;
  }
  
  .btn-p:hover {
    background-color: #1A0DAB;
  }
  
  .get-started-btn {
    background-color: #1A0DAB;
  }
  
  .contact-sales-btn {
    background-color:  #1A0DAB;
    ;
  }
  
  .badge {
    
   
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
  }
  
  .popular-badge {
    position: absolute;
    background-color: #ff4d4d;
    top: -10px;
    right: 30px;
}
  
  
  .save-badge {
   
    
    background-color: #1A0DAB;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    margin-bottom: 16px;
    align-self: center;

  }
  
  @media screen and  (min-width: 480px){}
    /* .mobile-cnt{
    display: none;}
  } */


  @media screen and  (max-width: 480px){

.pricing-cntr{
 

}
    .pricing-cards-container{

      flex-direction: column;
     
    }
.outer-pluscard , .card-p ,.plus-card{
  width: 100%;
  box-sizing: border-box;
}
    
  }


  .pricing-wrapper {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f8ff;
  }
  
  .pricing-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .pricing-title {
    font-size: 24px;
    color: #000;
  }
  
  .pricing-subtitle {
    font-size: 14px;
    color: #666;
  }
  