/* body, html {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .forgot-password-container {
    position :relative;
   

    width: 400px;
    padding: 20px;
    background-color:rgba(255, 255, 255, 1);
    border-radius: 10px;
    width: 395px;
height: 399.98px;
top: 150px;
left: 30%;
padding: 25px 89px 13px 89px;
gap: 20px;
border-radius: 15px 0px 0px 0px;
border: 1px 0px 0px 0px;


    text-align: center;
    box-shadow: 0px 4px 12px rgba(222, 228, 237, 1); 
  }
  
  .fp-text {
  
font-size: 24px;
font-weight: 700;
line-height: 36px;
text-align: center;

    color: rgba(0, 0, 0, 1);
    margin-bottom: 15px;
  }
  
  .fp-state {
   
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: center;

    color: rgba(181, 181, 181, 1);
    margin-top: 10px;
  }
  
  .fp-verify-button {
    background-color:rgba(0, 60, 255, 1);
    //width: 187px;
height: 42px;
padding: 10px 45px 10px 45px;
gap: 10px;
border-radius: 3px 3px 3px 3px;
border:none;
font-size: 18px;
font-weight: 700;
line-height: 21.78px;
text-align: left;

color:rgba(255, 255, 255, 1);

    margin-top: 25px;
    cursor: pointer;
  }
  .fp-did{
 
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;
color:rgba(0, 0, 0, 1);

  }
  
  .verify-button:hover {
    background-color: #0056b3;
  }
  
  .fp-resend-link {
   
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;

    color: rgba(0, 60, 255, 1);
    cursor: pointer;
  }
  
  .fp-back-to-login {
    color: #000;
    margin-top:50px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
   
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;

    
    justify-content: center;
    align-items: center; 
  }
  
  .fp-footer {
    margin-top: 40px;
  
font-size: 14px;
font-weight: 500;
line-height: 19.99px;
text-align: center;

    color: rgba(149, 156, 182, 1);
  }
  