
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
.main-faq-container {
  
    
   
}
/* .nav-faq{
    width: 1,440px;
height: 69px;
gap: 210px;
border:1px solid black;

} */

.below-nav-container{
    margin-top:40px;
    display:flex;
    gap:21px;
    box-sizing: border-box;
    margin-left: 71px;
    margin-right: 73px;
    margin-bottom: 10px;
}
.accordian-container{
  width:100%;
  box-sizing: border-box;
  
}

.accordian-outer-container {
  width: 100%;
  margin-left: auto;
  
}

.faq-item {
 
  padding: 10px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #E0E7FF;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background 0.3s ease;

 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}


.faq-item.active {
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;

 
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: -0.12800000607967377px;
text-align: left;
color:rgba(21, 21, 21, 1);

}

.faq-icon {
    display: flex;             
    justify-content: center;   
    align-items: center;       
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background-color: rgba(234, 239, 255, 1);
    font-size: 24px;
    color: rgba(26, 13, 171, 1);
  }
  

.faq-answer {
  padding-top: 10px;
  margin-left:20px;
font-size: 12.5px;
font-weight: 400;
color:rgba(116, 116, 116, 1);
line-height: 24px;
letter-spacing: -0.38px;


  
}




.faq-text{
/* width: 225px;
height: 185px;
top: 184px;
left: 71px; */
/* margin-left:50px; */
/* border:1px solid black; */
/* padding:0px 5px 30px  */


}
.faq-ineer-text{
  
  font-size: 36px;
  font-weight: 700;
  line-height: 53.06px;
  letter-spacing: 0.02em;
  text-align: left;
 
  
}

/* .contact-container {
 width: 1,070px;
height: 374px;
top: 1069px;
left: 316px;
padding: 0px 0px 24px 0px;
gap: 16px;
border-radius: 0px 0px 0px 0px;
//border:1px solid black;
margin-right: -17px;
background-color:white;

} */
.contact-container {
  width: 100%;
  box-sizing: border-box;
    /* width: 1,070px;
height: 374px; */
/* top: 1069px;
left: 316px; */
/* padding: 5px 8px 10px 8px; */
gap: 16px;
border-radius: 10px ;
//border:1px solid black;
margin-right: -17px;
background-color:white;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
margin-top:20px;
padding-bottom:24px;
box-shadow: 0px 5px 10px 0px #006AFF40;

}
  
  .contact-us {
    border-bottom: 0.76px solid #F4F4F4;
    padding: 16px;
    box-shadow: 0px 3px 3px 0px #3342570D;

    box-sizing: border-box;
font-size: 18px;
font-weight: 600;
line-height: 18.9px;
text-align: left;
color:rgba(51, 66, 87, 1);

    
    margin-bottom: 16px;
  }
  
  .contact-form {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    
  }
  
  .form-group11 {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding-left: 16px;
    
  }
  
  .form-group11 label {
    
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: left;
color:black;

    margin-bottom: 8px;
  }
  
  .form-group11 input
  {
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    
    padding: 10px 8px 10px 8px;
    gap: 10px;
   
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
color:rgba(151, 164, 175, 1);
    
border: 1px solid #D0DBE9;
  
    
   
    
  }
  .form-group11 textarea {
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    /* height: 20px; */
    padding: 10px 8px 100px 8px;
    gap: 10px;
    font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
color:rgba(151, 164, 175, 1);
    
border: 1px solid #D0DBE9;
   
    
       resize:none;
  }

  input:focus{
    outline: none; 
    border: 0.76px solid #003CFF;
  }
  
  textarea:focus{
    outline: none; 
    border: 0.76px solid #003CFF;
  }
  .form-group11 input::placeholder,
  .form-group11 textarea::placeholder {
    color: #888;
  }
  
  .send-btn11 {
    align-self: flex-end;
    width: 62px;
height: 39px;
padding: 8px 14px 8px 14px;
gap: 10px;
border:none;
border-radius: 5px 5px 5px 5px;

background-color:rgba(45, 129, 229, 1);


font-size: 14px;
font-weight: 400;
line-height: 22.4px;
text-align: center;
color:rgba(255, 255, 255, 1);


    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-btn:hover {
    background-color: #0056b3;
  }


  @media screen and (max-width:480px) {
 
   
    .below-nav-container{
      margin:32px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    .faq-inner-text{
      height: auto;
     display: flex;
     flex-direction: column;


 
    }
 
    .faq-text{
      align-self: center;
      margin:auto;
      height: auto;
      display: flex;
      flex-direction: column;
    }
    .accordian-container,.accordian-outer-container,.contact-container{
      width: 100%;
      margin:auto;


    }
    
    .accordian-container{
      /* margin-top:-80px; */
    }
    .faq-answer {
      padding-top: 10px;
      margin-left:20px;
    
    letter-spacing: 0px;
    
    
      margin-top: -10px;
    }
    .faq-question {
      display: flex;
      justify-content: space-between;
      //margin-left:10px;
    
     
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color:rgba(21, 21, 21, 1);
    
    }
  }
  .text1{
    /* padding-left:70px; */
    margin:0;
  }

  .text2{
    /* padding-left:70px; */
    margin:0;
  }
  .text3{
    /* padding-left:70px; */
    margin:0;
    color:rgba(26, 13, 171, 1);
  }

  .footerhelp{
    

  }

  
  
  

  @media screen and (max-width:480px) {

    .main-faq-container{
      padding:0;
    }
    .below-nav-container{

      display: flex;
      flex-direction: column;
      align-self: center;
      margin-bottom: 10px;
    }
    .faq-inner-text{
      h1{
        margin:0;
        padding:0;
      }

    }

    .faq-text{
      align-self: center;
      margin:auto;
    }
    .accordian-container,.accordian-outer-container,.contact-container{
      width: 100%;
    }

    .contact-container{
      box-sizing: border-box;
      width:100%;
    }
  }