/* Container for center alignment and responsiveness */
.account-review-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    padding: 20px;
    margin:auto;
    
    font-family: Arial, sans-serif;
  }
  
  /* Card for the message */
  .account-review-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Loading spinner animation */
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #4b8cf8;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto 20px;
  }
  
  /* Text styling */
  h2 {
    font-size: 1.5rem;
    color: #333;
    margin: 10px 0;
  }
  
  p {
    color: #777;
  }
  
  /* Animations */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .account-review-card {
      padding: 20px;
    }
  
    h2 {
      font-size: 1.2rem;
    }
  }
  


  .loading-dots {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .loading-dots span {
    animation: dot-blink 1.5s infinite;
    opacity: 0;
  }
  
  .loading-dots span:nth-child(1) {
    animation-delay: 0s;
  }
  .loading-dots span:nth-child(2) {
    animation-delay: 0.3s;
  }
  .loading-dots span:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes dot-blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  