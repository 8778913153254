@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
.container{
  /* width: 100%; */
  height: 69px;
  display: flex;
  flex-direction: row;
 padding-right: 16px;
 padding-left:16px;
  margin: 0;
 background-color:  rgba(234, 239, 255, 1);
}
.headercontainer{
  height: 69px;
  align-items: center;
  width: 100%;
  
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  box-sizing: border-box;
 
 
}
.frame1 {
  gap:111px;
  height:100%;
  align-items: center;
  padding: 10px;
  width: 70%;
  display: flex;
  
 
  /* padding: 10px; */
  box-sizing: border-box;
 
 
}
 
.RaisaLogo{
 
}
 
.RaisaLogoimg{
  
 
}
.headermain{
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
 
 
}
 
.header-ul{
  /* height: 9.075vh; */
  width: 100%;
  list-style-type: none;
  display: flex;
  /* flex-direction: row; */
  margin: 0;
  padding: 0;
  gap: 20px;
}
 

 
.headerli{
  align-items: center;

    width: 100%;
    gap: 8px;
    display: flex;
    height: 100%;
}
 
.headerli-span{
  width: 100%;
  margin:0;
 
font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 19.36px;
text-align: left;
 
}
.headerli-img{
  /* width: 14.4%;
  height: 36.75%; */

  width: 18px;
  height: 18px;
  filter: none; /* Ensure no unwanted filters are applied */
  fill: inherit; /* Allow SVG to inherit or use its internal fill */
 
 
}
.frame2{
  height: 100%;

  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
 
}
.header-logout{
   width: 28.937%;
 
  display:flex;
  justify-content: center;
  /* margin-right: 5.788%; */
  height:10.926vh;
 
  color: #1A0DAB;
 
  align-items: center;


}
 
.header-logout-btn{
  padding: 0;
    /* height: 66.11%; */
    height:39px;
    box-sizing: border-box;
    /* width: 84%; */
    width:125px;
    border: 1px solid #003CFF;
    background-color: transparent;
    color: #1A0DAB;
    border-radius:  0.375rem;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.21rem;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px #8890C233;
 
}

@media(max-width: 500px){
  .header-logout-btn{
    font-size:16px;
    margin-left:36px;
  }
 
}


 
.header-dash-logout{
  width: 28.937%;

 display:flex;
 justify-content: center;
 /* margin-right: 5.788%; */
 /* height:10.926vh; */

 color: #1A0DAB;

 align-items: center;


}

.header-dash-logout-btn{
 padding: 0;
   /* height: 66.11%; */
   height:39px;
   box-sizing: border-box;
   /* width: 84%; */
   width:125px;
   border: 1px solid #003CFF;
   background-color: transparent;
   color: #1A0DAB;
   border-radius:  0.375rem;
   font-family: Inter;
   font-size: 1rem;
   font-weight: 700;
   line-height: 1.21rem;
   cursor: pointer;
   box-shadow: 0px 2px 4px 0px #8890C233;

}
 
@media screen and (max-width:760px) {
 
  .container{
    background-color: white;
    padding-right: 16px;
    padding-left: 0;
  }
  
}

@media screen and (max-width:767px) {
 
  .header-logout{
    width: 77%;
   /* padding:10px 0px 10px 0px; */
    display:flex;
    justify-content: flex-end;
    margin-right: 10%;
    height:39px;
  }

  .headerli-span {
    display: none;
  }

  .frame1{
    gap: 45px;
  }
  .dis{
    display: none;
  }
  .header-dash-logout,.header-dash-logout-btn{
    display:none;
}
.header-dash-logout-btn{
    display: block;

}

}

@media (min-width:480px) {

  .profile-nav, .profile-pic {
    display: none;
  }
  
}

