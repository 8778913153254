/* @media screen and (max-width : 480px) {

    .header-dash-logout,.header-dash-logout-btn{
        
    }
    .header-dash-logout-btn{
       

    }
    
}

@media screen and (min-width : 480px) {

    .profile-nav , .profileimgnav ,.profile-pic{
       display: block; 
    }

    

}
 */

 .profileimgnav {
    box-sizing: border-box;
    width: 47px;
    height:55px;
    padding: 10px 5px;
  
 }
.profile-nav , .profileimgnav {
    box-sizing: border-box;
    width: 47px;
    height:55px;


  
   
 }
 .nav-profile-pic{
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-sizing: border-box;
 }


 @media  (max-width:480px) {
    .frame2-m{
    display:none;
    }
   
 }

 .filtermodal-content-p{
    position: absolute;
    background-color: #fff;
 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 200px;
    box-sizing: border-box;
    right:2%;
    display: flex;
    flex-direction: column;
    width: Fill (185px)px;
height: Fixed (140px)px;


border-radius: 12px 12px 12px 12px;

font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;
color:rgba(132, 132, 132, 1);



    /* right: 100%; Position the modal to the left of the icon */
    /* top: 0; Aligns it with the top of the icon */
     /* Optional: Add spacing between the modal and the icon */
  }