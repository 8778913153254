.sidebar-container{
  /* width: 17%; */

}


.profile-sidebar1 {
  background-color: #ffffff;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10.69px;
  padding-bottom: 10.69px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  gap: 16px;
}

.profile-line-request {
  display: flex;
  flex-direction: column;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:18px
}

.profile-picimg {
  width: 73px;
  height: 73px;
  border-radius: 120.3px;
  /* border-radius: 50%; */
}

.text-info {
  width:100%;
  /* margin-left: 15px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-Name {
  
  color:#000000;
  
font-size: 16px;
font-weight: 600;
line-height: 24px;



}

.profile-web {
  font-family: Inter;
font-size: 11px;
font-weight: 500;
line-height: 13.31px;


  width: 100%;
  font-size: 11px;
  color: #9A9A9A;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.profile-line {
  border-top: 1px solid #D8D8D8;
  margin: 15px 0;
}

.request-info {
   margin-left:14px;
  color: rgba(35, 152, 82, 1);
 
  font-size: 12px;
font-weight: 500;
line-height: 14.52px;

color:#239852;
}

.req-info-li {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.dot {
  height: 4px;
  width: 4px;
  background-color: rgba(35, 152, 82, 1);
  border-radius: 50%;
  margin-right: 8px;
  

}

.btn {
  display: flex;
  justify-content: center;
}

.edit-btn {
  /* width: 100%;
  height: 39px; */
  //padding: 11px 47px;
  border-radius: 5px;
  width: 175px;
height: 39px;

gap: 10px;
border-radius: 5.96px 5.96px 5.96px 5.96px;
margin-top:8px;

font-size: 14px;
font-weight: 600;

text-align: center;



  

  background-color: #EFEFEF; /* Button color */
  color:#000000;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-items: center;
}

/* .edit-btn:hover {
  background-color: #d7d6d6; 
} */


@media screen and (max-width:768px) {
  .profile-sidebar1 {
    box-shadow: none;
  }
  
}