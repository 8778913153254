.projects-section {

  display: flex;
flex-direction: column;
    width: 100%;
    gap:7px;
    padding: 10px;
    

    box-sizing: border-box;
    /* //border:1px solid black; */
  }
  .projectseciton-sub{
    display: flex;
    flex-direction: column;
    
    gap:7px;
  }

  .search-bar{
    padding: 0 5px;
    /* padding-right: 10px;
    padding-left: 10px; */
  }

  .card-list1{
    padding: 10px;
    width:98%;
    display:flex;
    flex-direction:row;
    gap: 15px;
    
   
    
   
 
   
  }


  .allprojects-filter-cntainer{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    /* height: 9.678vh; */
    height: 49px;
    padding: 0 10px;
  }
 
  .card2-list{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    gap:6px;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    border: 1px solid #B7B7B7;
    border-radius: 11px;
    
    
    box-sizing:border-box;
  }

  .popular-pagination-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
        /* height: 9.678vh; */
        height: auto;
   
    opacity: 1; /* Set this to 1 to make it visible */
   
    padding: 0 10px;


  }

  .popular-projects{
    
    width: 100%;
    
    
    
   
    
  }
   .pp{
   width:100%;
   height: 24px;
   display: flex; /* Align items horizontally */
   align-items: center; /* Vertically center the text and image */


font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: center;
box-sizing: border-box;
white-space: nowrap;
text-align: left;


color:rgba(0, 0, 0, 1);


  }
.ap{
  display: flex; /* Align items horizontally */
  align-items: center; /* Vertically center the text and image */
  
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: center;
color:rgba(0, 0, 0, 1);

}
  .pagination-popular {
    
    display: flex;
    justify-content: end;
    width: 100%;
   

    /* margin-right:96px; */
    
    
   
  }

  .pagination-all-projects {
    margin-top: 9px;
    display: flex;
    justify-content:center;
    width: 100%;
    /* margin-bottom:; */
    
    
   
  }


  .project-cards {
    display: flex;
    gap: 20px;
  }
  
  .project-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  .project-card h3 {
    margin-bottom: 10px;
  }
  
  .project-item {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .project-card span {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
  }

  /* Ensures that the modal is positioned correctly */
.filter-icon , .filter-container{
  /* display: inline-block; */
  align-items: center;
  align-self: center;
  justify-content: center;
  /* position: relative; */
  
}
  




/* .pagination-container {
  display: flex;
  align-items: center;
  gap: 10px;
} */

/* .pagination .arrow,
.pagination .double-arrow {
  display: flex;
  align-items: center;
  cursor: pointer;
} */

/* .pagination .arrow:disabled,
.pagination .double-arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
} */

  /* .filter{
    position: static;
  } */
  /* .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
  } */
   .pagination1 {
    width: 100%;
    display: flex;
    justify-content:center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .page-item {
    display: flex;
    justify-content: center; /* Center the content inside each page item */
    align-items: center;
    width: 32px; /* Adjust the width as needed */
    height: 32px; /* Adjust the height as needed */
    border-radius: 10%; /* Make them circular, optional */
    background-color: #ffffff; /* Background color for each page item */
    cursor: pointer;
    margin-right:5px;
  }
  
  .active {
    background-color: #f0f0f0;
    border-color: #ddd;
    font-weight: bold;
  }
  
  .page-item:hover {
    background-color: #e5e5e5;
  }
  
  .pagination a {
    text-decoration: none;
    color: #000000;
  }
  
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    box-sizing: border-box; 
  }
  .filtermodal-content{
    position: absolute;
    
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 200px;
    box-sizing: border-box;
    right:2%;
    display: flex;
    flex-direction: column;
 
padding: 16px 0px 0px 16px;
gap: 5px;
border-radius: 12px 12px 12px 12px;

font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;
color:rgba(132, 132, 132, 1);



    /* right: 100%; Position the modal to the left of the icon */
    /* top: 0; Aligns it with the top of the icon */
     /* Optional: Add spacing between the modal and the icon */
  }

  .modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden; /* Prevent background scroll */
  }
  
  .edit-profile1 {
    
  }
  .card2list-pagination-all{
    display: flex;
    flex-direction: column;
  }
 
  @media only screen and (max-width:600px) {

    .projects-section{
      /* padding:0; */

    }
    .card2-list{
      padding: 5px;
    }
   

    .filterm{
      display:flex;
         padding:5px;
         /* margin-right:-100px; */
      /* position: relative; */
    }

    .filter-options {
      position: absolute;
      top: 0;
      right: -300px; /* Offscreen initially */
      max-width: 300px;
      height: 100%;
      background-color: white;
      transition: right 0.3s ease-in-out;
      box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
    }
    
    .filter-options.open {
      right: 0; /* Slides in when open */
    }
    
    .filterm-img {
      cursor: pointer;
    }


      .filtermodal-content{
        top:14%;
      }


    .filter-container{
      display:flex;
      
    }
    .card-list1{
      width:100%;
      padding:0;
     margin-left: 5px;
      overflow-x: auto;
      gap:4px;
      /* flex-direction: column; */
      scrollbar-color: #003CFF #EAEFFF; /* Firefox: Thumb color and track color */
      /* scrollbar-width: thin; Firefox: Thin scrollbar */
    }

    .card-list1::-webkit-scrollbar {
      /* width: 8px; Scrollbar width */
      height: 8px; /* Horizontal scrollbar height */
    }
    
    .card-list1::-webkit-scrollbar-track {
      background: #EAEFFF;
      /* Track background color */
      border-radius: 4px; /* Optional rounded corners */
    }
    
    .card-list1::-webkit-scrollbar-thumb {
      background: #1A0DAB;
   /* Thumb (scroll handle) color */
      border-radius: 4px; /* Optional rounded corners */
    }
    
    .card-list1::-webkit-scrollbar-thumb:hover {
      background: #1A0DAB;
   /* Thumb color on hover */
    }


    .popular-pagination-heading {
      /* Stack items vertically on small screens */
        /* align-items: center; Center items horizontally in the column */
        /* Let the height adjust based on content */
        /* Add a gap between the elements for better spacing */
    
  }
.sort-img{

  content: url('../../Images/mobilesort.svg');
}

.filter-options.open{
  display: flex;
}


.filter-icon{
  padding:5px;
}
.popular-prjct-desktop-pagination{
  display: none;
}
.popular-prjct-mobile-pagination{
  display: flex;
}

}
.all-prjct-mobile-pagination{
  display: none;
}

@media screen and (min-width:600px) {

  
  
  .filterm{
    display:none;
  }
}

  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 0px;
  }
  
  .input-project-section-checkbox {
    width: 16px;
    height: 16px;
    margin-right: 8px; /* Adjust spacing between checkbox and label text */
  }

  .requestmodal-content-dashboard {
    position: absolute;
    //background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 200px;
    box-sizing: border-box;
    right: 0%;
    top:-2%;
    display: flex;
    flex-direction: column;
    max-width: 185px;
    height: 140px;
    padding: 16px 0px 0px 16px;
    gap: 5px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(132, 132, 132, 1);
    
    /* Add initial hidden state */
    transform: translate(100%, -100%); /* Start off-screen to the top-right */
    
    
    /* Add animation */
    animation: slideInFromTopRight 0.5s forwards;
  }

  .filter-sort-container{
    display: flex;
    gap:4px;
    align-items: center;
  }