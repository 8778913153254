@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');


.contactsale-container{
   display:flex;
   flex-direction: column;
    
    height:auto;
    background-color: white;
    border: 1.5px solid #1A0DAB;

   
/* height:153px; */
padding: 15px ;
gap: 18px;
border-radius: 15px ;
box-shadow: 0px 7px 21px 0px #252C6133;

box-shadow: 0px 3px 8px 0px #8890C240;



}

.contactsale-subcontainer{

    display: flex;
    flex-direction: column;
    align-items: center;
    gap:18px;

    padding: 0px 7px 0px 7px;
}

.contactsale-text{

    display: flex;
    flex-direction: column;
    gap:5px;

 flex-wrap: nowrap;
}

.contactsale-text1{
    font-family: Inter;
    font-size: 12px;
    font-weight: 900;
    line-height: 14.52px;
    text-align: left;
    padding:5px;
    
}
.contactsale-text2{

    font-family: Inter;
    font-size: 13.5px;
    font-weight: 500;
    line-height: 16.34px;
    text-align: left;
    padding:5px;
    span{

        color: #1A0DAB;
    }
}

.contactsale-btn{

    display: flex;
    justify-content: center;

    
}

.sales-button{

    //padding: 11px 47px;
    border-radius: 5px;
    width: 175px;
  height: 39px;
  
  gap: 10px;
  border-radius: 5.96px 5.96px 5.96px 5.96px;
  margin-top:8px;
  
  font-size: 14px;
  font-weight: 600;
  
  text-align: center;
  
  
  
    
  
     /* Button color */
    color:#000000;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-items: center;


    border: none;
    color: white;
    background-color: #1A0DAB;
}