
.side-div {
  width:100%;
 
  gap: 0px;
}

.side-container{
  background-color: #ffffff;

    /* padding: 20px; */
    /* width: 10.625%; */
    padding-top: 26px;
    /* width:35.741vh; */

    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    h3 {
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      margin: 10px 0;
      //background-color: #f0f0f0;
      padding: 10px;
      border-radius: 5px;
      //styleName: Bold 12;

font-size: 12px;
font-weight: 700;
line-height: 14.52px;
letter-spacing: 0.01em;
text-align: left;
color:rgba(0, 60, 255, 1)

      /* color: rgba(0, 60, 255, 1); */
      
    } 
}

.side-sub-container{
  width:  70.47%;
  display:flex;
  flex-direction: column;
  margin-left: 13px;
  box-sizing: border-box;
  gap: 20px;
}
.side-div{
 
}
.key{
  width: 100%;
  height:100%

}
.side-div-data{
  
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  letter-spacing: 0.01em;
  text-align: left;
  
}
.side-img{

}

.side-div h3 {
  cursor: pointer;
  margin: 0;
  
  
  //background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  color: rgba(0, 60, 255, 1);
  
font-size: 12px;
font-weight: 700;
line-height: 14.52px;
letter-spacing: 0.01em;
text-align: left;

  
} 
.side-checkbox-li{
  display: flex;
  margin-top: 14px;
  gap: 5px;
  flex-direction: column;
}

.side-div label {
  display: inline-flex; /* Align checkbox and label text side by side */
  align-items: center; /* Vertically center the checkbox and text */
 /* white-space: nowrap; */
  font-size: 10px;
  font-weight: 600;
  color:rgba(0, 0, 0, 1);
  
  gap: 10px;
line-height: 12.1px;
letter-spacing: 0.01em;
text-align: left;


  cursor: pointer;
  box-sizing:border-box;
}

.check-box {
  /* margin-right: 10px; Keep a small gap between the checkbox and label text */
  width: 18px;
height: 18px;

border-radius: 3px;
border: 1.25px  rgba(209, 213, 219, 1);


}


.toggle-icon {
  margin-left: 10px; 
  font-weight: bold; 
  font-size: 18px;
  
}







/* Remove the default browser styles */
input[type="checkbox"].check-box {
  margin: 0;
  /* margin-right: 9px; */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  background-color: white;
  border: 1.25px solid rgba(209, 213, 219, 1);
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

/* Add a checkmark when checked */
input[type="checkbox"].check-box:checked {
  background-color: #4a90e2; /* Your custom color when checked */
  border-color: #4a90e2;
}

input[type="checkbox"].check-box:checked::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  top: 2px;
  left: 5px;
  transform: rotate(45deg);
}




