/* Main container */
/* .inbox-container {
  
    width: 521px;
    height:auto;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 0px rgba(0, 106, 255, 0.25);

    background-color: white;
 
    
    padding: 20px;
    padding-top:25px;
    
    position: relative;
    margin-top:10px;
  } */
  .inbox-container {
    width: 90%; /* Use percentage for flexible width */
    max-width: 521px; /* Ensure it doesn't exceed a maximum width */
    height: auto;
    margin: 10px auto; /* Add some margin on top for spacing */
    border-radius: 8px;
    box-shadow: 0px 5px 10px 0px rgba(0, 106, 255, 0.25);
    background-color: white;
    padding: 20px;
    padding-top: 25px;
    position: relative;
}

/* Adjust padding and margins for smaller screens */
@media (max-width: 768px) {
    .inbox-container {
        padding: 15px;
        margin-top: 15px; /* Adjust margin for better spacing */
    }
}

@media (max-width: 480px) {
    .inbox-container {
      box-shadow: 0px 5px 10px 0px #006AFF40;

        padding: 10px;
        margin-top: 10px; /* Reduce margin further on smaller screens */
    }
}

  
  /* Close button */
 /* Close button styling */
 .inbox-close-btn {
  width: 20px;
    height: 20px;
    border-radius: 30%;
    flex-shrink: 0; 
  position: absolute;
  top: 20px;
  right: 20px;  /* Adjusted for proper alignment */
   /* Set height for better visibility */
  padding: 0;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0;  /* Removed extra margin */
}


  
  
  /* Header section */
  .inbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top:40px;
    
  }
  
  /* Message Title */
  .inbox-message-title {
    
font-size: 14px;
font-weight: 400;
line-height: 16.71px;
text-align: left;
color:rgba(0, 0, 0, 1);

  }
  
  /* View Profile Button */
  .inbox-view-profile-btn {
    border-radius: 5px ;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 22.4px;
text-align: center;


    background-color:rgba(0, 40, 170, 1);
    color:rgba(255, 255, 255, 1);
    
   
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    
    
  }
  
  /* .inbox-view-profile-btn:hover {
    background-color: #2c3c94;
  } */
  
  /* Message list */
  .inbox-message-list {
    height: 250px; /* Fixed height */
    overflow-y: auto; /* Makes the section scrollable when content exceeds the height */
    margin: 20px 0;
    padding: 10px 0;
    //border-bottom: 1px solid #ddd;
  }
  
  .inbox-message-item {
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .inbox-your-message {
    background-color:rgba(225, 225, 225, 1);
    /* width:495px; */
  }
  
  .inbox-polkadot-message {
    background-color:rgba(237, 243, 255, 1);
    /* width:478px; */
  }
  
  .inbox-message-header {
    display: flex;
    flex-direction:column;
    margin-bottom: 5px;
  }
  .inbox-clock{
    
font-size: 10px;
font-weight: 400;
line-height: 12.1px;
text-align: left;
color:rgba(102, 102, 104, 1);

  }
  
  .inbox-message-sender {
    
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color:rgba(44, 44, 46, 1);
    
  }
  
  .inbox-message-time {
    
font-size: 10px;
font-weight: 600;
line-height: 18px;
text-align: left;
color:rgba(44, 44, 46, 1);

  }
  
  .inbox-message-content {
    
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
padding:10px;

    color: rgba(44, 44, 46, 1);
  }

  
  /* Message input section */
  .inbox-message-input-section {
   
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .inbox-message-heading{
    margin:0;
    //font-family: SF Pro Display;
font-size: 14px;
font-weight: 400;
line-height: 16.71px;
text-align: left;
color:rgba(0, 0, 0, 1);
  }
  
  .inbox-message-input {
    
    padding: 10px 8px 102px 8px;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #D0DBE9;
    
    resize: none;
    font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
color:rgba(151, 164, 175, 1);
;

    
    
  }
  
  .inbox-message-input:focus {
    outline: none;
    border-color: #3e4eb8;
  }
  
  /* Send actions (buttons) */
  .inbox-send-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
  }
  
  .inbox-attach-btn {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .inbox-send-btn {
    width: Hug (62px)px;
height: Hug (39px)px;
padding: 8px 14px 8px 14px;
gap: 10px;
border-radius: 5px ;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 22.4px;
text-align: center;


    background-color:rgba(0, 40, 170, 1);
    color:rgba(255, 255, 255, 1);
    
   
    border: none;
    cursor: pointer;
  }
  
  /* .inbox-send-btn:hover {
    background-color: #2c3c94;
  } */
  