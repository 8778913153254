.pagination-container {
    display: flex;
    justify-content: flex-end;
}
 
.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 5px;
    justify-content: center;
}
 
.scrollable-pages {
    display: flex;
    overflow-x: auto;
    max-width: 200px; /* Adjust as needed */
    gap: 5px;
}
 
.scrollable-pages::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}
 
.pagination li {
    border-radius: 8px;
    display: flex;
    gap: 5px;
    color: black;
}
 
.pagination button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: black;
    background-color: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 300ms ease-out;
}

.dots{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: black;
    background-color: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 300ms ease-out;

}
 
.pagination button:hover {
    opacity: 0.8;
    background-color: #2F80ED;
    
}
 
.pagination .active button {
    background: #F1F1F1;
    border-radius: 8px;
}
 
/* .pagination .dots {
    padding: 8px 12px;
    color: #999;
} */
 
.pagination button:disabled {
    border-radius: 8px;
    color: #999;
    cursor: not-allowed;
}

.pagination-container {
    max-width: 348px;
    margin: 0 auto;
}

@media (max-width: 450px) {
    .pagination-container {
       max-width: 212px;
    }

    .scrollable-pages {
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        transition: max-width 0.3s ease;
        max-width: 100px;
    }

    .scrollable-pages.expanded {
        overflow-x: scroll;
        max-width: 50px;
        /* max-width: 100%; */
    }
}
