.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content-project {
    
    position: relative; 
    background-color: #fff;
    padding: 20px;
  
    width: 40%; 
    max-height: 40vh; 
    overflow-y: auto; 
    border-radius: 12px;
  }
  
  .modal-close-button {
    
    width:24px;
    height:24px;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  .modal-category-list {
    list-style: none;
    padding: 0;
    margin-top: 20px; 
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    justify-content: flex-start;
  }
  
  .modal-category-list li {
    display:flex;
    padding: 4px 8px;
    border: 1px solid rgba(206, 224, 245, 0.7);
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap; 
    
    font-weight:300;
    color:rgba(0, 0, 0, 1);
    justify-content:center;
  align-items:center;
  }
  
  .modal-category-list li:hover{
    background-color:rgba(206, 224, 245, 0.7) ;
  }