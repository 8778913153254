
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
.card-container {
  /* width: 881px;  */
 /*  height: 200px; */
 width: 100%;
 max-height:197px;
  gap:24px;
  padding: 16px 28px 10px 16px;
  border-radius: 8px ;
  border: 1px solid #ddd;
  cursor:pointer;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  
 
  box-sizing: border-box;
}
.funding-btn{
  display: flex;
    flex-wrap: nowrap;
    border: none;
    max-width: 360px;
    max-height: 28px;
    padding: 0 12px;
    white-space: nowrap;
    background-color: rgba(231, 240, 250, 1);
    color: rgba(0, 59, 255, 1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    align-items: center;
    overflow: scroll;
}
.funding-btn::-webkit-scrollbar {
  display: none; /* Hides the scrollbar in Webkit-based browsers (Chrome, Safari, Edge) */
}



@media screen and (max-width:786px) {
  .funding-btn{
   
    max-width: 127px;
    font-family: Inter;
font-size: 10px;
font-weight: 500;
line-height: 20px;


  }
  .funding-btn::-webkit-scrollbar {
    display: none; /* Hides the scrollbar in Webkit-based browsers (Chrome, Safari, Edge) */
  }

  .header-card {
    justify-content: space-between;
}
}

.card-logo {
  /* width: 7.086%;
  height: 28.623%;
   */
   /* width: 100%;
  height: 100%;
    */
   box-sizing: border-box;
 
}


.cardlogo-img{

  width:56px;
   height:56px;
 
  box-sizing: border-box;
}

.cardlogo-img-m{
  width:40px;
   height:40px;
 
  box-sizing: border-box;

}

.card2-content{
  /* width: 90%;  */
  
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
 
  
}

.card2-sub-content{
  /* width: 95.5%; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

}
.title-name-icon{
  display: flex;
  align-items: center;
}
.title-name{
padding: 8px;
font-size: 14px;
font-weight: 600;
line-height: 16.94px;

font-family: 'Inter';




 
  color:rgba(20, 20, 20, 1);
}
.verified-icon {
  width: 16px;
  height: 16px;
  margin-bottom: 5px;
  
}

.header-card {
  justify-content: space-between;
}
.header-card , .Location-Card , .body-content  {
  display: flex;
  /* width: 100%; */
  /* margin: 10px; */
  box-sizing: border-box;

}


.card2-details{
  display: flex;
  flex-direction: column;
  gap:2px;
}

.Location-Card {
  padding: 8px;
  gap:23px;
  /* div {
      
  color:rgba(94, 102, 112, 1);
  display: flex;
  align-items: center;

 
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;



  }
  img {
      
    width: 22px;
    height: 22px;
    object-fit: contain;


  } */
}

.loc-div,.rcvd-div,.sent-div{
  display: flex;
  align-items: center;
  
  display: flex;
      
  color:rgba(94, 102, 112, 1);
  
  align-items: center;


 
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;

}
.loc-div{
 
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.received-block{
  display: flex;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
  margin: 0;
  font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
font-family: 'Inter';
}
.card2-icons{
  width: 22px;
   height: 22px;
   margin-right: 11px;
}
.card2-icons-m{
  width:18px;
  height:18px;
}

.rcvd-text{
  display: inline-block;
  line-height: 1; 
}
.body-content,.bio {
  
width: 100%;
/* max-height: 51px; */
  
 


}
.bio {
  padding: 0px 8px;
  color:rgba(20, 20, 20, 0.7);

  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; /* Line height set to match the overall line spacing */
  max-height: calc(1.2em * 3); /* Control max height based on line count */
}

.cardin {
  /* height:46px; */
  
}
.cardin-list {
  box-sizing: border-box;
  gap:20px;
  width: 100%;
  cursor:pointer;
  display: flex;
  justify-self: start;
  align-items: center; /* Aligns the items vertically in the center */
  /* Adds space between the list and the "more" div (optional) */
}

.cardin-list ul {
  justify-self: start;
  width:100%;
 
  margin: 0;
  padding: 8px;
  list-style: none; /* Remove bullet points */
  display: flex; /* This will display the list items in a row */
  
  gap: 10px; /* Space between list items */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
  overflow: hidden; /* Hide overflow content */
}

.cardin-list li {
  /* width: 100%; */
  /* margin-right: 10px; */
  /* background-color: #f0f0f0; */
  display: flex;
  /* height:30px; */
  padding: 5px;
  border: 1px solid rgba(206, 224, 245, 0.7);
  border-radius: 5px;
  font-size: 12px;
  font-weight:300;
  line-height: 20px;
  color:rgba(0, 0, 0, 1);
  justify-content:center;
  align-items:center;
 

  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
  overflow: hidden; /* Hide overflow content */
  
}


.cardin-list li:hover{
  background-color:  rgba(206, 224, 245, 0.7);
}
.more-list {
  justify-self: start;
  align-items: center;
  width: 100%;
   /* Space between the list and the "+X more" text */
  font-weight: bold;
}

/* .cardin {
  flex-wrap: wrap;
  flex-direction: row;
  ul {
      display: flex;
     
      gap:20px;
     
      flex-direction: row;
  }
 
li {
  display: flex;
  row-gap: 20px;
  flex-direction: row;
}
}



li {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}


.cardin-list{

  display: flex;
}
.more-list {

 justify-content: center;
} */


.bookmark{
  width: 20px;
height: 24px;
gap: 10px;



}

@media screen and (max-width:780px){
   .card-desktop{
    display: none;
   }

  .card-mobile{
    width: 100%;
    box-sizing: border-box;
     display:flex;
     flex-direction: column;
     gap:4px;
  }

  .photo-name{
    gap: 3px;
    display: flex;
    height: 40px;
  }
  .card-logo{
    margin-left: 8px;
 width: 40px;
 height: 40px;


  }

  .card-container{
    width: 100%;
    padding: 5px;
    max-height: 100%;
  }
  .body-content{
    padding:5px;
  }
  .Location-Card{
    padding: 8px;
  }
  .cardin-list, .cardin-list ul{
   
  }
  .loc-div,.rcvd-div{
    gap:6px;
  }

  .header-card{
    width: 100%;
   
    display: flex;
    justify-content: space-between;
  }
.funding-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}
}

@media screen and (min-width:600px) {

  .card-mobile{
    display:none;

 }
.card-desktop{
  display: flex;
  gap:24px;
}
}
 
@media (max-width: 500px) {
  .bio {
    width: 400px;
  }
 
}