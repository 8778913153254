.msg-header{
    display: flex;
    gap:13px
}

.msg-name-time{
    padding: 6px 0;
    display: flex;
    flex-direction: column;
}

.req-profile-pic{
    width: 48px;
height: 48px;


}

.req-message{
    display: flex;
    flex-direction: column;
    gap: 7px;
    box-sizing: border-box;
    background-color:  #EDF3FF;
   
padding: 5px 20px 5px 20px;

border-radius: 6px ;



}

.request-message-content{
    /* padding:10px;
    font-family: 'Inter';
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none; */
width: 100%;
height: auto;
box-sizing: border-box;
padding: 10px;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
word-wrap: break-word; /* Breaks long words if needed */
overflow-wrap: break-word; /* Ensures lines break for long content */
white-space: normal; /* Enables multi-line wrapping */
text-underline-position: from-font;
text-decoration-skip-ink: none;
align-content: space-between;

}