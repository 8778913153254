 
 
  .mainnavbar {
    
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
   box-sizing: border-box;
    display: flex;
   
    align-items: center;
    
    background-color:rgba(234, 239, 255, 1);
   
   
   display: flex;
   gap: 210px;
   box-sizing: border-box;
   flex-direction: row;
   justify-content: space-between;
  }
 
  .logo {
    width:142px;
    display: flex;
    align-items: center;
  }

.raisa-logo-img{
  width: 122px;
  height:49px;
}


.mainnav-frame2{
  width:100%;
 display:flex;

 justify-content: space-between;
padding-top: 5px;
padding-bottom: 5px;
box-sizing: border-box;
}

.mainnav-links{

  display: flex;
  justify-content: flex-start;
}
 
  .logo-letter {
    font-size: 24px;
    font-weight: 900;
    color: #1A1AFF;
    margin-right: 4px;
  }
 
  .logo-name {
    font-size: 18px;
    font-weight: 500;
    color: #1A1AFF;
  }
 
  .nav-links {
    list-style: none;
    display: flex;
   margin:0;
   padding:0;
   gap:52px;

  }
 
  .nav-links a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    color: black;
    transition: color 0.3s;
  }
 
  .nav-links a:hover {
    color: #1A1AFF;
  }

 .nav-links-li{
  display: flex;
  width: 86px;
  height: 56px;
  align-items: center;
  text-align: center;
  justify-content: center;
 }
 
  
 .mainnav-buttons{
  display: flex;
  justify-content: flex-end;
  gap:10px;
 }
 .login , .signup{
  display: flex;
  width: 125px;
  height: 59px;
  align-content: center;
  align-self: center;
  justify-content: center;
  align-items: center;
 }

.login-lnk , .signup-lnk{
  width:105px;
  height:39px;
  background-color: transparent;
  border: 1px solid #003CFF;
  border-radius: 6px;
  

}
.login-lnk {
  
font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 19.36px;
color:  #1A0DAB;
&:hover{
  background-color: #0028AA;
  color: white;
}

}

.signup-lnk {
  
  
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
 background-color: #0028AA;

  color:  white;
  &:hover {
    color:#0028AA;
    background-color:transparent;

  }
  
  }



@media screen and  (max-width: 768px){

  .mainnav-links{
    display:none;
  }

  /* .signup,.signup-lnk{
    display: none;
  } */

  .mainnavbar , .mainnav-frame2{
    display:none;
    /* background-color: #ffffff; */
  }
  .mainnavbar{
    gap:0px;
  }
 
  .mainnav-frame2{

    justify-content:flex-end;

}
.mainnav-buttons{
  display:none;
}
  
}

@media screen and  (max-width: 768px){

.menu-icon-m{
  position: absolute;
  left: 0px;
  top: 60px;
  background: white;
}

.mainnavbar-m{
  position: relative;
  
  background-color: #ffffff;
  /* width: 100%; */
  display:flex;
  justify-content: space-between;
  /* margin: 0px 22px 0px 22px; */
/* padding-left: 22px; */

padding-right:22px;
box-shadow: 0px 4px 4px 0px #00000040;
z-index: 1;

}

.mainnav-buttons-m{
  gap:10px;
  display: flex;
  justify-content: space-between;
}
.login-m,.signup-m{
  display: flex;
  align-items: center;

}
.login-lnk-m{
  width: 68px;
  height: 28.83px;
 
  /* padding: 7px 18px 6.83px 17px; */
  gap: 7.39px;
  border-radius: 4.43px ;
  
  border: 0.74px solid #003CFF;
  background-color: transparent;
  color: #003CFF;

}

.login-lnk-m:hover{
  color:white ;
  background-color:#0028AA;
}
.signup-lnk-m{
  width: 68px;
  height: 28.83px;

  /* padding: 7px 18px 6.83px 17px; */
  gap: 7.39px;
  border-radius: 4.43px ;
  background-color: #0028AA;
  border: 0.74px solid #003CFF;

  color:  white;
}
  

  .signup-lnk-m:hover{
    color:#0028AA;
    background-color:transparent;
  }



}


@media screen and  (min-width: 768px){

  .mainnavbar-m{
    display: none;
  }
}




.menumodal-content-p{
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  /* min-width: 200px; */
  box-sizing: border-box;
  left: 0px;
  top: 100px;
  display: flex;
  flex-direction: column;
  border: 1px solid #EAEFFF;
  border-radius:5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(132, 132, 132, 1);}



  .menu-options{
    font-family: 'Inter';
font-size: 16px;
font-weight: 700;
line-height: 19.36px;
text-align: left;
text-decoration-line: none;
text-decoration-skip-ink: none;
color: #000000;

  }


  .menu-cntr{
    display: flex;
    flex-direction: column;
    
    padding: 10px ;
    gap: 10px;
    border-radius: 5px;
    
    /* border: 1px solid #EAEFFF */
    
  }