@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

   
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
   
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
   
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
   
  .App-link {
    color: #61dafb;
  }
   
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .dashboard-container {

    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    /* overflow-x: hidden; */
}

.dashborad-navbar {
  
   
    box-sizing: border-box;
   
    
}

.main-content {
    margin-left: 23px;
    /* width: 100vw; */
    /* width: calc(100vw - 23px); Adjusted to account for margin-left */
    gap: 15px;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    background-color: rgba(234, 239, 255, 1);
     /* To prevent scroll */
}

.dashborad-sidebar {
    width: 13.403%; /* 193px of 1440px */
    margin-top: 26px;
}

.dashboard-prjct-section {
    margin-top: 18px;
    box-sizing: border-box;
    position: relative;
    width: 63.958%; /* 921px of 1440px */
}

.dashboard-profile {
  display:flex;
  flex-direction: column;
    margin-top: 25px;
    width: 16.111%; /* 232px of 1440px */
    box-sizing: border-box;
    gap:43.5px
}

/*  */





@media screen and (max-width:480px)  {
 .dashborad-sidebar,.dashboard-profile {
display: none;

 }
 .main-content{
  margin-left: 0;

}
.dashboard-prjct-section{
  width: 100%;
}
.projects-section {
  width: 100%;
  
}
}

@media screen and (max-width:768px) {
  .dashborad-navbar{
    display: none;
  }
  .main-content{
   display: block;
  }
  .mobile-main-content{
    display: flex;
    
  }
}
.mobile-main-content{
  display: none;
}
.mobile-dash-profile-pic{
  width:37px;
  height:37px;
}

.dash-logout-btn{
  margin:  10px auto;
  padding: 0;
  /* height: 66.11%; */
  height:39px;
  box-sizing: border-box;
  /* width: 84%; */
  width:125px;
  border: 1px solid #003CFF;
  background-color: transparent;
  color: #1A0DAB;
  border-radius:  0.375rem;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.21rem;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px #8890C233;

}
.dash-logout-btn:hover{
  color:white;
  background-color: #1A0DAB;
}


.dash-navbar-m{
  position: relative;
  
  background-color: #ffffff;
  width: 100%;
  display:flex;
  justify-content: space-between;
  /* margin: 0px 22px 0px 22px; */


z-index: 1;
}

@media(min-width:768px){
  .dash-navbar-m{
    display:none;
  }
}
