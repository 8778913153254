.edit-profile-container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: 500;
  }
  