.App {
    display: flex;
    font-family: sans-serif;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  /* .pagination {
    list-style: none;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    cursor: pointer;
  
    gap:5px;
  } */
  
  /* .active {
    background-color: rgba(47, 128, 237, 1);
    
  }
  
  .page-item {
    list-style: none;
    padding: 2px 12px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    background-color:rgba(255, 255, 255, 1);
    &:hover{
      background-color: rgba(47, 128, 237, 1);
    }
    &:active{
      background-color: rgba(47, 128, 237, 1);
    }
  }
   */