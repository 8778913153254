@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
.insuff-container {
  border: 2px solid #EAEFFF;
    max-width: 400px;
    margin: auto;
    padding: 10px;
   
    border-radius: 12px;
    box-shadow: 0px 7px 21px 0px #252C6133;

box-shadow: 0px 3px 8px 0px #8890C240;


    background-color: #fff;
    font-family: Inter;
  }
  
  .insuff-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 16px; */
  }
  
  .insuff-header h2 {
    text-align: left;
    font-size: 18px;
    color: #333;
  }
  
  .insuff-section {
    /* display: flex; */
    flex-direction: column;
    gap: 16px;
  }
  
  .insuff-warning,
  .insuff-option {
    /* border: 1px solid #eee; */
    padding: 7px;
    border-radius: 6px;
    /* background-color: #f9f9f9; */
    display: flex;
  
    gap: 8px;
  }
   .insuff-option.free:hover{
    background-color: #fff;
  }
  .insuff-option:hover{
    background-color:  #4034c3;
    
    
  }
  .insuff-option:hover .insuff-link {
    color: #fff;
  }
  .insuff-option:hover .insuff-link.free{
    color:  #1A0DAB;
  }
  /* .insuff-warning:hover{
    background-color:  #1A0DAB;
  } */

.insuff-icon-msg{
  display: flex;
  align-items: center;
  gap:8px;
}
  .insuff-option{
    flex-direction: column;
  }
  
  .insuff-container{
    display:flex;
    flex-direction: column;
  }

  .insuff-sub-container1{
    margin-left: 28px;
  }
  /* .insuff-icon {
    /* font-size: 20px;
    color: #4a4aef; 
  } */
  .insuff-line{
    border: 0.5px solid #E9EFFF;
     
  }
  
  .insuff-message {
    
    color: #767676;

    margin: 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  
  .insuff-balance {
   
    color: #000000;
    margin: 0;
    font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 16px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;


  }
  .insuff-balance:hover{
    color:  #0028AA;

  }

  .insuff-option-head{
    font-family: Inter;
font-size: 15px;
font-weight: 500;
line-height: 16px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color:  #000000;
margin:0;

  }

  
  .insuff-description {
    font-size: 12px;
    color: #555;
    margin: 0;
  }
  
  .insuff-link {
    font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 16px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    font-size: 14px;
    color: #4a4aef;
    text-decoration: none;
    
  }
  
  .insuff-link:hover {
    text-decoration: underline;
  }
  