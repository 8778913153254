/* html {
    position: relative;
    width:100%;
    height:100%;
}
.imghash{

    position: absolute;
    left: 0;
    bottom: 0;

   
}
.main{

    width: 100%;
    height: 100%;
    position: relative;
}

.form-dis{

    margin-bottom: 40px;
} */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
body{
    font-family: 'Inter';
}

/* .main{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
} */


  .imghash{
    width:300px;
    height:300px;
    position: absolute;
    bottom: -50px;
    left: 0;
    z-index: -1;
    overflow: auto;
    display: flex;
  }

.a{
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
  .app-l {
    width: 100vw;
    height: 100vh;
    overflow: auto; 
    /* Enables scrolling */
    /* position: relative;
    
     */
  }
  
  /* Image fixed at the bottom-left corner */
  

  /* Main app styles */

  
  /* Login container styles */
  .login-container {
   
  

    margin:50px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .login-modal {
    background-color: white;
    /* width: 300px; */
    /* height: 
    759.94px; */
    padding:18px 41px 18px 41px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    
  }
  .close{
    /* right: 40%; */
    /* left: 85%; */
    top: -13px;
    left: 50%;
    position: relative;
border-style: none;

    width: 45px;
height: 45px;
/* top: 62px;
left: 874px; */
padding: 11.25px;
gap: 11.25px;
border-radius: 112.5px;
/* opacity: 0px; */


  }
  .logo-close{
    right: 47%;
    top: 1%;
    position: absolute;
position: absolute;
  }

  .logo-R{
    right: -20%;
    position: relative;
    width: 122px;
    align-self: center;
  }
  .login-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .login-subtitle {
    color: #888;
    font-size: 14px;
    margin-bottom: 20px;
  }
.full-container{

}

  .email-password-container-me{
    display:flex;
    flex-direction: column;
    gap: 16px;
    
  }
  .email-password-container{
    display: flex;
    flex-direction: column;
    gap:15px;

  }



  .email-cntr{
    display: flex;
    flex-direction: column;
    gap: 8px;

  }




  .login-form {
    text-align: left;
  }
  
  .login-form label {
    display: block;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .required-star {
    color: red;
  }
  
  .login-form input[type="email"],
  .login-form input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .login-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .signup-link {
   margin: 0;
   
   font-family: Inter;
   font-size: 14px;
   font-weight: 600;
   line-height: 24px;
   text-align: center;
   
  }
  
  .signup-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .signup-link a:hover {
    text-decoration: underline;
  }
  
 
  .full-container  {
    display:flex;
    flex-direction: column;
    gap:20px;
  }

  .full-sub-container{
    display:flex;
    flex-direction: column;
    gap:20px;
/* margin-left: 68.5px;
margin-right: 68.5px; */
  }

  .link-footer{
    display:flex;
    flex-direction: column;
    gap:76px;
  }

  .rights{

    
font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 19.99px;
text-align: center;
color: #959CB6;


  }

.login-welcome-cntr,.welcome-title-sub-titile{
    display: flex;
    flex-direction: column;
}

 .login-txt{
    
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    
 } 

 .welcome-title-sub-titile{
    display: flex;
    flex-direction: column;

 }

 .welcm-txt{
    
font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;

 }


 .welcm-sub-txt{
   
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: center;
color: #B5B5B5;

 }


 .total-form-container{
    display: flex;
    flex-direction: column;
    gap:17px;
 }




 @media screen  and (max-width:768px) {
   .app-l{
     display:none;
   }
   .mobile-screen{
    gap:50px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    
    background-color: white;
   }
  
   

.login-welcome-cntr{
  display: flex;
}
.login-txt,.welcm-txt,.welcm-sub-txt{
  text-align: left;
}

 }

 @media screen and (min-width:768px){
  .app-l{
    display:block;
  }
  .mobile-screen {
  display: none;
  }
 }
.login-container-m{
  display: flex;
  flex-direction: column;
  gap: 35px;
  /* margin: auto; */
  background-color:  white;
  margin-left:20px;
  margin-right:39px;

}
 /* Container for navbar */
.navbar-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 22px;
  box-shadow: 0px 4px 4px 0px #00000040;
z-index: 1;
  background-color:  white; /* Adjust background color */
}

/* Styling for the logo */
.logo-m {
  font-size: 24px;
  font-weight: bold;
  color: #00008b; /* Adjust color to match "RAISA" blue */
}

/* Styling for the hamburger menu icon */
.menu-icon-m {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.menu-icon-m span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #000; /* Adjust color for the icon */
  border-radius: 2px;
}


.side-menu {
  position: fixed;
  top: 0;
  left: -250px; /* Start off-screen */
  width: 250px;
  height: 100vh;
  background-color: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.5);
  display: none;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  transform: translateX(-100%); /* Hide initially by translating fully left */
  transition: transform 0.3s ease; /* Animation for sliding effect */
  z-index: 10;
}

/* Open State of the Side Menu */
.side-menu.open {
  transform: translateX(0); 
  display:flex;
  left: 0; /* Slide in from the left */

}

/* Close Button */
.close-btn {
  align-self: flex-end;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Links in the Side Menu */
.side-menu a {
  color: #003CFF;
  text-decoration: none;
  font-size: 18px;
  padding: 8px 0;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}


.menumodal-content-l
  {
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    /* min-width: 200px; */
    box-sizing: border-box;
    right:25px;
    top: 45px;
    display: flex;
    flex-direction: column;
    border: 1px solid #EAEFFF;
    border-radius:5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(132, 132, 132, 1);}
.signup-span{
  font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #003CFF;

}