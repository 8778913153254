.rs-container {
  width: 400px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  text-align: center;
  width: 432px;
  height: 325.96px;
  padding: 64px 41px 13px 41px;
  gap: 20px;
  border-radius: 15px 15px 15px 15px;
  border: 1px 0px 0px 0px;
  box-shadow: 0px 4px 12px rgba(222, 228, 237, 1);
}

.rs-head {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  color: rgba(0, 0, 0, 1);
  margin-bottom: 15px;
}

.rs-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;

  color: rgba(181, 181, 181, 1);
  margin-bottom: 10px;
}

.rs-input-container {
  position: relative;

  margin-bottom: 15px;
}
.rs-em {
  width: 44.46px;
  height: 20.98px;
  padding: 0px 0.46px 0.98px 0px;
}

.rs-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
}

.rs-reset-button {
    
  background-color:rgba(0, 60, 255, 1);
  
  width: 141px;
  height: 42px;
  padding: 10px 45px 10px 45px;
  gap: 10px;
  border-radius: 3px 0px 0px 0px;

  padding: 10px 20px;
  border: none;
  border-radius: 5px;
 
font-size: 18px;
font-weight: 700;
line-height: 21.78px;
text-align: left;

  cursor: pointer;
  margin-top: 25px;
}

.rs-button-text{
    color: rgba(255, 255, 255, 1);
    display:flex;
    justify-content: center; /* Centers the content vertically */
    align-items: center; /* Centers the content horizontally */
}

.reset-button:hover {
  background-color: #0056b3;
}

.rs-sign-in-link {

font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;

  color:rgba(0, 60, 255, 1);
  cursor: pointer;
  margin-top:25px;
}

.rs-required {
  color: red;
}
.rs-rem {

font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;
color:rgba(0, 0, 0, 1);
margin-top:25px;
}
