

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
.request-page-main-container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding:0;
  margin:0;
  overflow-x: hidden;
  /* position: relative; */ /* Uncomment if you need this */
  /* margin: 0 auto; */ /* Uncomment if you need this */
}

@media (max-width: 480px) {
  .request-page-main-container {
   
    overflow-y: scroll;
    width: 100%;
    height: auto; /* Changed height to auto for better responsiveness */
    margin: auto; /* Centered margin */
  }
}


.requestpage-container{
width: 100%;
/* margin: 0 1.6%; */
  display: flex;
  flex-direction: column;
 
  gap: 10px;
 

}
@media (min-width: 760px) {
  .requestpage-container{
    
      margin-left:20px;
    
    }
}

.request-page-navbar{
  
  box-sizing: border-box;
 
}
.sidebar-req-cntainer{
width: 100%;
  display: flex;
 
 margin-top:8px;
  gap: 16px;


}

.requestpage-sidebar{

  width: 13.403%;
  box-sizing: border-box;
}
.input-req{
width: 90%;
border-radius: 5px ;

height: 48px;
gap: 0px;


  
  border: 1px solid #ddd;
  box-sizing:border-box;
  
 
}
@media screen  and (max-width:768px){
  .input-req{
    padding: 24px;
  }
}
.requesttable{
 
  
  width: 80.07%;
  height: auto;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 7px 21px 0px rgba(37, 44, 97, 0.2), /* Stronger shadow */
              0px 3px 8px 0px rgba(136, 144, 194, 0.25); /* Softer shadow */
  
  .search-bar-req{

      width:90%;
      height: auto;
      padding: 10px;
      gap: 10px;
      
      }
      .pagination-request {
        margin-top:10px;
        display: flex;
        justify-content: center; /* Centers horizontally */
        align-items: center; /* Centers vertically */
        width: auto;
        height: 32px;
        gap: 10px;
        //border: 1px solid black;
        
      }
      

      .filter-icon{
        
          justify-content: flex-end;
          align-items: center;
          align-content: center;
          
      }
     /*  @media (max-width: 500px) {
        .filter-icon {
          
          margin-top:-10px;

         
         
         
        }
      } */
       
      


}


@media (max-width:760px){
  .requesttable{
    width:100%;
  }
  .search-filter {
  
    width: 50%;
    //flex-direction: column;
  }
  .requestpage-sidebar{
    display:none;
  }
  .input-req{
    width:100%;
  }
  .request-page-navbar{
    display: none;
  }
}

.filter-mobile-design{
  padding:10px;
}


@media (min-width:761px){
  .filter-mobile-design{
    display:none;
  }
  
}



.search-filter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;

}

@media (max-width:500px){
  
  .search-filter{
    
   background-color:rgba(234, 239, 255, 1);
  
  
  }
}



.requestfilter-icon{
position: relative;
}
.requestmodal-content{
/* min-width: 200px;
  box-sizing: border-box;
right:10px;
z-index: 1000;
position: absolute;
background-color: #ffffff;
display: flex;
flex-direction: column;
padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */

  position: absolute;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 200px;
    box-sizing: border-box;
    right:2%;
    display: flex;
    flex-direction: column;
    width: Fill (185px)px;
height: Fixed (140px)px;
padding: 16px 0px 0px 16px;
gap: 5px;
border-radius: 12px 12px 12px 12px;

font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;
color:rgba(132, 132, 132, 1);

}

.requestmodal-content1 {
  position: absolute;
  //background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 200px;
  box-sizing: border-box;
  right: 0%;
  top:-2%;
  display: flex;
  flex-direction: column;
  width: 185px;
  height: 140px;
  padding: 16px 0px 0px 16px;
  gap: 5px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(132, 132, 132, 1);
  
  /* Add initial hidden state */
  transform: translate(100%, -100%); /* Start off-screen to the top-right */
  
  
  /* Add animation */
  animation: slideInFromTopRight 0.5s forwards;
}

@keyframes slideInFromTopRight {
  0% {
      transform: translate(100%, -100%); /* Start off-screen */
      opacity: 0;
  }
  100% {
      transform: translate(0, 0); /* End at its original position */
      opacity: 1;
  }
}



.filterdiv{
padding: 10px;
}
@media (max-width: 500px) {


.requesttable{
  overflow: scroll;
}
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0px;
}

.input-request-checkbox{
  width: 16px;
    height: 16px;
    margin-right: 8px; 
 /* Space between checkbox and text */
}






.page-item-request {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid rgba(241, 241, 241, 1);
  border-radius: 4px;
  cursor: pointer;
  width: 32px;  /* Adjust this size as per your design */
  height: 32px;
  

gap: 10px;
border-radius: 8px;
border: 1px 0px 0px 0px;
  transition: background-color 0.3s ease;
}

.page-item-request:hover {
  background-color: rgba(47, 128, 237, 1); /* Change background on hover */
}

.active-request {
  background-color: white; /* Change the background color of the active page */
  color: black;
}

.arrow-request-paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  
  width: 32px;  /* Adjust this size as per your design */
  height: 32px;
  

gap: 10px;
border-radius: 8px;
border: 1px 0px 0px 0px;


  border: 1px solid rgba(241, 241, 241, 1);
  transition: background-color 0.3s ease;
}

.pagination-container-request{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Adds space between each page number */
  list-style:none;
}




.req-navbar-m{
  position: relative;
  
  background-color: #ffffff;
  /* width: 100%; */
  display:flex;
  justify-content: space-between;
  /* margin: 0px 22px 0px 22px; */

/* box-shadow: 0px 4px 4px 0px #00000040;
z-index: 1; */
}
.req-nav-cntr{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  /* gap:7px; */
}
/* .req-raisa-logo-img{
  width:140px;
  height:49px;
} */


.req-logout-btn{
  width: 25%;
height: 39px;
gap: 10px;
border-radius: 6px ;

border: 1px solid #003CFF;
padding: 10px 25px;

box-shadow: 0px 2px 4px 0px #8890C233;
background-color: transparent;
color:  #1A0DAB;

font-family: 'Inter';
font-size: 16px;
font-weight: 700;
line-height: 19.36px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;


}

.req-logout-btn:hover{
  color:white;
  background-color:  #1A0DAB;
}

.req-header-ul{
  /* height: 9.075vh; */
  /* width: 100%; */
  list-style-type: none;
  display: flex;
  /* flex-direction: row; */
  margin: 0;
  padding: 0;
  gap: 23px;
  padding: 0 10px;
  align-items: center;
}

.req-headerli{
  align-self: center;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:376px) {

  .req-header-ul{
    gap:13px;
  }
  .req-logout-btn{
    padding:7px 17px;
  }
  
}
@media screen and (max-width:337px) {

  .req-header-ul{
    gap:7px;
  }
  .req-logout-btn{
    padding:5px 15px;
  }
  
}
@media(min-width:768px){
  .req-navbar-m{
    display:none;
  }
}
