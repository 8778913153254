
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
.card11 {
 
  width: 24%;
  height: 145.6px; 
 

padding: 11.17px 14.36px 6px 14.36px;
gap: 9px;
border-radius: 7.98px 0px 0px 0px;


  
  /* padding: 11.17px 14.36px 6px 14.36px; */
  border-radius: 7.98px 7.98px 7.98px 7.98px;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
}

.card-header1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  /* width:180px; */
  box-sizing: border-box;
  
}
.card-header1-sub{
  display:flex;
  gap:4.96px;
}

.company-logo {
  width: 23.81px;
height: 23.81px;
/* object-fit: contain; */
gap: 4.96px;
border-radius: 1.98px;


  
}

.cardcompany-name  { 
 
 
  font-family: 'Inter';

  color:rgba(0, 0, 0, 1);
/* font-size: 12.57px;
font-weight: 600;
line-height: 19.15px;
text-align: left; */

margin: auto;

font-family: 'Inter';
font-size: 9.57px;
font-weight: 600;
line-height: 19.15px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;




 
}

.card-bookmark {

  width: 14px;
  height: 18px;
  
 
}

.card-content1 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box ;
  /* width: 180px;  */
  
 gap:11.17px;
  
 
}
.card-content1-sub{
  gap:7.98px;
  display: flex;
  flex-direction: column;
}

.website-link {

  font-family: 'Inter';
  font-size: 11.17px;
  font-weight: 500;
  line-height: 15.95px;
 
  
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  
  display: block; /* Make the link a block-level element */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  white-space: nowrap; /* Prevent the text from breaking to the next line */
 
}
.request-type-first {
  color:rgba(0, 0, 0, 1);
  
  font-weight:bold;
  font-family: 'Inter';
font-size: 8.77px;

line-height: 9.92px;
text-align: left;

  display: block; /* Make the link a block-level element */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  white-space: nowrap; /* Prevent the text from breaking to the next line */
  margin:0;
}

.request-type {
  font-family: 'Inter';
  color: #8F9195;

 
  //font-size: 10px;
}

.request-details {
 display: flex;
 flex-direction: column;
 gap:7.98px;
}

.request-details p {
  display: flex;
  align-items: center;
 margin: 0;
  
}

.request-details .icon {

  width: 14px;
  height: 14px;
  margin-right: 9px;
}

.request-received, .request-sent {
  color:rgba(0, 0, 0, 1);
  
  font-weight:bold;
  
font-size: 8.77px;

line-height: 9.92px;
text-align: left;
font-family: Inter;
  
}

.request-sent1{
  color: #8F9195;
  font-family: 'Inter';
  font-size:8.77px;
}


@media screen and (max-width:600px) {
  .card11{
    width:199px;
    box-sizing: border-box;
    
  }

  .card-content1{
    width:179px;
  }

  .card-content1-sub{
    gap:14px;
  }

}
