.verify-email-container {
  width: 400px;
  padding: 20px;

  border-radius: 10px;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  border: 1px 0px 0px 0px;
  box-shadow: 0px 4px 12px rgba(222, 228, 237, 1);
}

.ver-em {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;

  color: rgba(0, 0, 0, 1);
  margin-bottom: 15px;
}

.ver-em-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;

  color: rgba(181, 181, 181, 1);
  margin-bottom: 15px;
}

.code-input-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
}

.ver-input {
  width: 50px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
}

.verify-button {
  width: 187px;
  height: 42px;
  padding: 10px 45px 10px 45px;
  gap: 10px;
  border-radius: 3px 0px 0px 0px;

  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;

  background-color: rgba(0, 60, 255, 1);
  
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  margin-bottom: 15px;
}

.vs-button-text{
    display:flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.verify-button:hover {
  background-color: #0056b3;
}

.ver-back-to {
  color:rgba(0, 0, 0, 1);
 
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: center;

  
}
.ver-login{
    color:rgba(0, 60, 255, 1);
 
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    
      cursor: pointer;
}

.ver-footer {
  margin-top: 20px;
  
font-size: 14px;
font-weight: 500;
line-height: 19.99px;
text-align: center;

  color:rgba(149, 156, 182, 1);
}
