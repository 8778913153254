
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
body{
  font-family: 'Inter';
}
.companydetails-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-wrap: normal;
  overflow: hidden;
}

.companydetails-navbar{
  /* width: 100%; */
  /* box-sizing: border-box; */
  /* padding-right:2% ; */
}
.companydetails-sub {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  /* margin-bottom: 30px; */
}                          
 
.bread-container {
  width: 100%;
  padding: 1%;
  margin-left: 12%;
}
 
.bbcmb {
  margin: auto;
  width: 100%;
  /* flex-wrap: nowrap; */
 
  /* justify-content: flex-start; */
  list-style: none;
  /* margin-left: 40px; */
}
 
.breadcrumb {
  margin: auto;
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
 
  /* justify-content: flex-start; */
  list-style: none;
}
 
.breadcrumb-item {
  gap: 4px;
  display: flex;
  flex-direction: row;
  height: inherit;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  a {
    position: relative;
    /* padding: 5px; */
 
    text-decoration: none;
    color: #667085;
    align-items: center;
    justify-content: center;
 
    &:hover {
      text-decoration: underline;
    }
  }
}
.a-div {
 
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  align-items: center;
}
 
.breadcrumb-item.active {
  
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
 
  background-color: transparent;
  color: #667085;
}
 
.breadcrumb-item::after {
  content: ""; /* Clear content to avoid text */
  background-image: url("./../Images/chevron-right.png"); /* Path to your image */
  background-size: contain; /* Adjust sizing */
  background-repeat: no-repeat; /* Prevent repetition */
  width: 16px; /* Set appropriate width */
  height: 16px; /* Set appropriate height */
  display: inline-block; /* Makes it act like an inline element */
  margin-left: 0px; /* Space between text and image */
  vertical-align: middle; /* Aligns with text */
}
 
.breadcrumb-item:last-child::after {
  content: "";
  display: none;
}
 
.company-details {
  width: 73.89%;
  height: 100%;
  margin: auto;
  
  color: #333;
 
  display: flex;
  flex-direction: column;
 
  gap: 10px;
  box-sizing: border-box;
  overflow-wrap: normal;
}
.cover-photo {
  width: 100%;
  height: 100%;
  
}
 
.header-container {
  width: 100%;
  height: 291px;
  display: flex;
  flex-direction: column;
 
  position: relative;
}
 
/* Header Section */
.header-section {
  width: 100%;
  height: 291px;
 
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
}
 
.header-section,
strong {
  font-weight: bold;
}
 
/* .profile-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 20px;
    max-width: 1200px;
    margin: 20px auto; */
 
/* .profile-info {
    display: flex;
    align-items: center;
}  */
 
.subconatiner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  background-color: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

}
 
/*
 chatgpt code
*/
.profile-container {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 1440px;
  gap: 21px;
  height: auto;
  margin-bottom: 24px;
}
 
.company-profile-logo {
  background-color: #ffffff;
  /* position: absolute; */
  
  width: 108px; /* 108px to percentage based on the width of the design (1440px) */
  border: 2px solid rgb(255, 255, 255);
  margin-bottom: 24px;
  border-radius: 4px;
  height: 108px; /* Keep aspect ratio */
}

 
.company-profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
 
}
 
.company-profile-text {
  max-width: 318px;
  margin-top: 8px;
  gap:8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}

.companyname-oneliner-container{
  display:flex;
  flex-direction: column;
  gap:4px;
  width: 100%;
  box-sizing: border-box;
}
.companyname-verification{
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
.company-name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #2F343A; /* Adjust the color according to your design */
  margin:0;
  display: flex;
  align-items: center;
}

.company-name img.verified-icon {
  margin-left: 8px;
  width: 18px;
  height: 18px;
}
.verified-icon-c{
  margin-left: 8px;
  width: 18px;
  height: 18px;

}
.oneliner {
  width: 100%;
  box-sizing: border-box;
font-family: 'Inter';
font-size: 12px;
font-weight: 500;
line-height: 16px;
text-align: left;
color: #2F343A;

  
  margin:0;
}

.location {
  width:100%;
  height: 100%;
  color: #6f7287; /* Similar color for location text */
  display: flex;
  align-items: center;
  margin:0;
}

.location-img {
  width: 16px;
  height: 16px;
  
}
  /* width: 7.907%;
  height: 7.907%;} */
.city-conuntry{
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  color: #6F747A;
}
 
.edit-profile-btn {
  background-color: #e7eaf1;
  color: #333;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
 
.edit-profile-btn:hover {
  background-color: #d3d9e1;
}
/*chat gpt */
.oneliner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  margin: 0px;
}
 
.inner {
  display: flex;
      align-content: center;
      gap: 8px;
      /* padding: 32px; */
      padding-right: 19px;
      flex-direction: column;
      justify-content: center;
  align-content: center;
  align-items: flex-end;
}
 
.send-request-btn {
  display: flex;
  flex-wrap: nowrap;
  border: none;
  width: auto;
  height: auto;
  padding: 4px 12px;
  white-space: nowrap;
  background-color: rgba(231, 240, 250, 1);
  color: rgba(0, 59, 255, 1);
 
border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

 

}

.send-request-btn:hover{
  color: white;
  background-color: #1A0DAB;

}
@media (max-width:768px){
.send-request-btn{
  /* margin-bottom: 60px; */
  position: relative;
  /* left: 13px; */
  top: -30px;
  /* position: absolute;
  bottom: 70px;
  right: 15px;
  height: 24px; */
  font-family: 'Inter';
font-size: 10px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
padding: 0px 3px;

}

}
 
/* Main Content */
.main-content1 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  /* margin: auto; */
  /* max-width: 1062px; */
  /* margin: 40px auto; */
  margin-left: auto ;
  margin-right: auto;
  margin-bottom: 40px;
}
 
.left-section1 {
   width: 99.1%;
  display: flex;
  flex-direction: column;
 
  gap: 16px;
}
 
.right-section-companydetails {
  box-sizing: border-box;
  height: 100%;
  width: 44.818%;
  display: flex;
  flex-direction: column;
 
  
  
  gap: 16px;
 
}
 
/* Cards */
.biodata-card1 {
  display:flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 18px;
  gap: 24px;
  border-radius: 8px;
 
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}
 
.biodata-heading {
  width: 100%;
  height: auto;
  margin:0;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #18191c;
 
  text-align: left;
}
.biodata-text {
  width: auto;
  color: #5e6670;
  margin:0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
 
.ecosystem-card1 {
  display:flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 18px;
  gap: 12px;
  border-radius: 8px;
 
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}
 
.ecosystem-heading {
  width: 100%;
  height: auto;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #18191c;
}
.ecosytem-text {
  height: auto;
  width: auto;
  gap: 10px;
  border-radius: 5px;
  text-align: left;
  padding: 8px;
  color: black;
  margin: 0;
 
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
}
 
.blockchain-data {
    width: auto;
    height: auto;
    margin:0;
  border: 1px solid #cee0f5b2; /* You can change the color or thickness */
  padding: 5px; /* Adjust padding as needed */
  display: inline-block; /* Ensures border wraps closely around text */
  border-radius: 5px; /* Optional: Adds rounded corners */
}

.partnership-card1 {
  display:flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 18px;
  gap: 12px;
  border-radius: 8px;
 
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}
 
.partnership-heading {
  width: 100%;
  height: auto;
 margin:0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
 
  color: #18191c;
}
.img-text-company{
  display:flex;
 align-content: center;
 align-self: center;
  width:80%;
  color:black;
  gap:7px;
}
 
.partnership-text {
  height: auto;
  width: auto;
  gap: 10px;
  border-radius: 5px;
  text-align: left;
  /* padding: 8px; */
  color: black;
  margin: 0;
 
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  
}
 
.whitepaper-card1 {
  display: flex;
  width: 100%;
  height: auto;
 
  padding: 18px;
  gap: 3px;
  border-radius: 8px;
 
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  /* white-space: nowrap; Prevent text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
  overflow: hidden; 

}
.whitepaper-heading {
  /* padding: 10px; */
  width: 100%;
  height: auto;
 margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-overflow: ellipsis;
  color: rgba(24, 25, 28, 1);
}
.whitepaper-text {
  width: 100%;
  height: auto;
  padding: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
 
  color: rgba(26, 13, 171, 1);
  text-overflow: ellipsis;
}
 
.statement-card1 {
  display:flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 18px;
  gap: 24px;
  border-radius: 8px;
 
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}
.statement-heading {
  width: 100%;
  height: auto;
 margin: 0;
  color: rgba(24, 25, 28, 1);
 
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.statement-text {
  width: 100%;
  height: auto;
  gap: 12px;
 margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
 
  color: #5e6670;
}
 
.categories-card1 ,.request-type-card1{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7.4565% 4.97%;
  gap:9px;
  background-color: #fff;
  
  border-radius: 8px;
 box-sizing: border-box;
 
}

.categories-heading ,.request-type-heading,.social-media-heading{
  width:91.042%;
  color: #18191c;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.categories-text ,.request-type-text,.ecosystem-text,.partnership-text,.request-type-text{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.category-item {
  white-space: nowrap;
  height: auto;
    width: auto;
  margin:0;
  gap: 5px;
  border: 1px solid #CEE0F5;
  /* padding:1.727%; */
  padding:5px;
  border-radius: 5px;
  font-size: 12px;
  color: #000000;
  cursor: default;
  font-weight: 300;
  line-height: 20px;

}
.category-item:hover{
  background-color:  #CEE0F5 ;
}

.category-more {
  color: blue;
  /* padding: 2%; */
  padding:5px;
  font-weight: 500;
  font-style: italic;
  line-height: 20px;
  cursor: pointer;
  align-self: center;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #CEE0F5;
}

.category-more:hover {
  background-color:  #CEE0F5 ;

  text-decoration: underline;
}

 
/* .request-type-card1 {
  width: 100%;
  height: auto;
  padding: 24px 16px 24px 16px;
  gap: 9px;
  border-radius: 8px;
  border: 1.5px;
 
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
} */
/* .request-type-heading {
  width: 100%;
  height: auto;
  
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
 
  color: rgba(24, 25, 28, 1);
} */
 
/* .request-type-text {
  width: auto;
  height: auto;

  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
 
  color: #000000;
 
  border: 1px solid #cee0f5b2;
  display: inline-block;
  border-radius: 5px;
  padding: 8px;
} */
 
.social-media-card1 {
  
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95.654%;
  height: auto;
  gap: 9px;
  box-sizing: border-box;
}
 
/* .social-media-heading {
  width: auto;
  height: auto;
  
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(24, 25, 28, 1);
} */
 
.social-media-text {
  width:90.91%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
 gap:10px;
  height: auto;
}

.social-media-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
 
.containericon {
  border-radius: 5px;
  padding: 14px;
  display: flex;
  /* width: 100%;
  height: 100%; */
  object-fit: fill;
  justify-content: center;
}

.containericon:hover{
  background-color: #1A0DAB;
}
.containericon:hover .icon-svg path  {
  
  fill: white; /* Change the fill color to white on hover */
  transition: fill 0.3s ease; /* Smooth transition effect */
}
.containericon:hover .icon-svg path .inside {
  fill:#1A0DAB ;
}
.containericon:hover .icon-svg .inside {
  fill: #1A0DAB; /* Change inner color to gold */
}

.companydetails-request-container{
  display: flex;
  flex-direction: column;
  width:100%;
  gap:7px;
  margin:0;


}
 
.stat-item1 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: auto;
  padding: 3.107% 4.97%;
  box-sizing: border-box;
  gap:10px;
 
}

.stat-item1-left {
  display: flex;
    gap: 7px;
    align-items: center;
    width: 81.035%;
}

.received1-img1 {
 
  width: 14.897%;
}

.stat-item1-text {
  display: inline-flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-family: Inter;
  font-size: 18.5px;
  font-weight: 700;
  line-height: 22.39px;
  text-align: left;
  width: auto;
  color: #000000;
}
/*  */
.stat-item1-right{

  /* width: 10%;
  height: 5%;
  padding: 8px 7px 8px 8px; */

}
.number-section{
  width: 44px;
  height: 26px;
  background-color: #1A0DAB;
 
  /* margin: 8px 7px 8px 8px; */
  /* padding: 2.759% 2.414% 2.759% 2.759%; */
  border-radius: 10%;
  align-self: center;
  text-align: center;
}

.number-stat {
  font-size: 15px;
  font-weight: 700;
  line-height: 23.15px;
  text-align: left;
  height: auto;
  align-self: center;
  text-align: center;
  color: white;
  
  margin: 0;
}
 
.companydetails-footer {
  display: none;
}
 
@media only screen and (max-width: 600px) {
  .companydetails-navbar{
    display:none;
  }
  .innern {
    display: flex;
    flex-wrap: nowrap;
    .send-request-btn {
      display: flex;
      flex-wrap: nowrap;
      font-size: 8px;
    }
  }
  .company-details {
    width: 100%;
    margin-top: 10px;
  }
  /* .bbcmb {
    display: none;
  } */
   .bread-container{
    margin-left: 5px;
    margin-top: 10px;
   }
  .header-container {
    width: 100%;
  }
  .main-content1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:16px;
    margin-bottom: 0;
  }
  .stat-item1 {
    display: none;
  }
  .left-section1 {
    padding:  0 5px;
    width: auto;
    display: flex;
    flex-direction: column;
 
    gap: 16px;
  }
 
  .social-media-text {
    width: 90%;
  }
  .right-section-companydetails {
    padding: 0 5px;
    
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
  .companydetails-request-container {
    display: none;
  }
 
  .companydetails-footer {
    display: block;
  }
}

@media screen and (max-width:360px) {
  .send-request-btn {
      display: flex;
      flex-wrap: nowrap;
      font-size: 8px;
      margin-left:-130%;
    }
 
}
@media screen and (max-width:768px) {
  .company-profile-text {  max-width: 203px;}
  .profile-container{gap:13px;}
}

.funding-btn1{
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  border: none;
  max-width: 360px;
  max-height: 28px;
  padding: 4px 12px;
  white-space: nowrap;
  background-color: rgba(231, 240, 250, 1);
  color: rgba(0, 59, 255, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  align-items: center;
  overflow: auto;

}
.funding-btn1-m{
  display: none;
}
@media screen and (max-width:480px) {

  .funding-btn1{
    /* top:20px;
    max-width:127px;
    z-index: 10000; */
    /* position: absolute;
        top: auto;
        bottom: 6px;
        max-width: 127px;
         */
    display: none;
  }
  
  .funding-btn1-m {
    
    position: absolute;
        top: auto;
        bottom: 6px;
        right: 19px;
        max-width: 127px;
        /* position: relative; */
        display: flex;
        flex-wrap: nowrap;
        border: none;
        /* max-width: 360px;
        max-height: 28px; */
        padding: 0px 2px;
        white-space: nowrap;
        background-color: rgba(231, 240, 250, 1);
        color: rgba(0, 59, 255, 1);
        border-radius: 4px;
        font-family: 'Inter';
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        
        align-items: center;
        overflow: auto;
  }
}

