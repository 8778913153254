.pagination-container-all{
    width: 328px;
}
/*  */

.scrollable-pages-all {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    transition: max-width 0.3s ease;
    gap: 5px;
}

.scrollable-pages-all.expanded {
    overflow-x: scroll;
    /* max-width: 100%; */
}
.scrollable-pages-all::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}