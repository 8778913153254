
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
.Requestcontainer {
  /* width: 100%; */
   
    overflow:scroll;
   

  }

  /* @media (min-width: 768px) {
    .Requestcontainer {
      
      width: 100%;
     
    }
  }
   
  @media (width: 912px) {
    .Requestcontainer {
      
      width: 100%;
   
    }
  }
   
  @media (width: 540px) {
    .Requestcontainer {
      //height: 40px;
      width: 100%;
    
    }
    
  } */

  .below-message-container{
    
    display:flex;
    flex-direction:column;
    width:50%;
    align-items:flex-start;
    
    
  }
  

  .table-container{
    /* width: 100%; */
    background-color: #ffffff;
    width:108.24%;
    cursor:pointer;
    //overflow-x: auto;
  }
  
  .segmented-control {
    margin-top:8px;
    display: flex;
    border-radius: 5px;
    background-color:rgba(234, 239, 255, 1);
    width: fit-content;
   
    padding: 8px;
    

  }
  .segment-icon path {
    fill: #1a0dab; /* Default SVG fill color */
  }
  
  .segment:hover .segment-icon path {
    fill: #fff; /* Color on hover */
  }
  
  .segment.active .segment-icon path {
    fill: #fff; /* Color when active */
  }
  
  /* Segment button styling */
  .segment {
    width: 50%;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    background-color: rgb(234, 239, 255);;
    color: #1a0dab; /* Text color */
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    border-radius: 8px;
   
    
  }



  
  .segment.active {
    background-color: #1a0dab;
    color: white;
  }
  
  .segment:hover {
    background-color: #1a0dab;
    color: white;
  }
  
  
 
  
  .pending-table {
    width:100%;
    border-collapse: collapse;
    overflow-x: auto;
  }

  .request-table{

   width:  112.42%;
   border-collapse: collapse;
    overflow-x: auto;
  }

  .reject-table{
    width:  100%;
    border-collapse: collapse;
     overflow-x: auto;
  }
  @media (max-width: 760px) {
    .request-table {
      
      width: 1550px; 
     
    }
    .reject-table{

      width: 1350px; 
    }
    .pending-table{

      width: 1350px; 
    }
    .segmented-control{
      flex-direction:row;
      overflow-x:scroll;
      width:95%;
    }

  }

  @media(max-width: 450px){

    .segmented-control{
      flex-direction:row;
      overflow-x:hidden;
      width:379px;
      margin-top: 32px;
      margin-left: 26px;
      margin-right: 25px;
      padding: 0;
      height: 25px;
    }
    .segment {
      //width: 50%;
      border: none;
      padding: 2px;
      cursor: pointer;
      font-size: 8px;
      background-color: rgb(234, 239, 255);;
      color: #1a0dab; /* Text color */
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;
      border-radius: 8px;
      font-family: 'Inter';
font-size: 8px;
font-weight: 600;
line-height: 21px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
      /* margin-top:15px; */
     
      
    }
  }



  .table-body{
    /* border-bottom: 1px;
    border-color: #1a0dab; */
 
  }

  .table-row{
    border-bottom: 1px solid rgba(245, 245, 245, 1);
  }
  .id-request{
   
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;


    /* width: 50px;

//height: 64px;
padding: 12px 24px 12px 24px;
gap: 12px;*/
border-bottom: 1px solid rgba(245, 245, 245, 1);

  }
  .name-request{
    display: flex;
    justify-content: center;
    img{
      width: 28px;
      height: 28px;
    }

    /* width: 160px;

    //height: 44ppx;
    padding: 12px 24px 12px 0px;
    gap: 20px;
    //border: 0px 0px 1px 0px; */

    border-bottom: 1px solid rgba(245, 245, 245, 1);

  }

  .date-request{
   
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    /* tect-align:center;

    width: 164px;


padding: 10px 10px 10px 4px;
gap: 12px;
border: 0px 0px 1px 0px; */
border-bottom: 1px solid rgba(245, 245, 245, 1);

  }

  .location-request{

    /* width: 160px;

    //height: 64px;
    padding: 12px 0px 12px 0px;
    gap: 12px;
    border: 0px 0px 1px 0px; */
   
    border-bottom: 1px solid rgba(245, 245, 245, 1);
  }

  .requestType-request{
/* 
    width: 160px;

    //height: 64px;
    padding: 12px 0px 12px 0px;
    gap: 12px;
    border: 0px 0px 1px 0px;
    */
    border-bottom: 1px solid rgba(245, 245, 245, 1);
  }

 
  .msg-image-request{
    width: 48px;
height: 48px;
padding: 9px 10px 9px 10px;

border-radius:200px;
margin-right:37px;


  }
  .below-message{
    
  
font-size: 10px;
font-weight: 500;



  }

.action-button-request{
  /* width: 155px;
//height: 64px;
padding: 12px 24px 12px 24px;
gap: 12px;
border: 0px 0px 1px 0px; */


}
.status-request{
  /* width: 160px;
//height: 64px;
padding: 12px 24px 12px 24px;
gap: 12px;
border: 0px 0px 1px 0px; */

font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;


}

/* .request-table th{
 width: 100%;
  font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    vertical-align: middle;
   
   


    border-bottom: 1px solid #F5F5F5;
    padding: 10px;
    
    position:relative;

} */


.req-name{
  margin: 0;
}
.tablehead{

  background-color:  #EAEFFF;
  
}

.tablerow{
  width: 100%;
  height: 44px;
  
  
}


  .table-heading{
    text-align: center;
    vertical-align: middle;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 0;
  }
  
.id, .idsend,.idreject{
  width: 50px;
  /* width: 4.007%; */
  padding: 0;
  
}
.nop,.nopsend,.noreject{
  width: 160px;
  /* width: 12.821%; */
  padding: 0;
}
.lstupdt,.lstupdtsend,.lstupdtreject{
  width: 164px;
  /* width:13.1411%; */
  padding: 0;
}
.msg,.msgsend,.msgreject{
  width: 394px;
  /* width: 31.571%; */
  padding: 0;
}
.loc,.locsend ,.locreject{
width: 160px;
/* width: 12.821%; */
padding: 0;

}
.reqtyp,.reqtypsend,.reqtypreject{
  width: 160px;
  /* width:12.821%; */
  padding: 0;
}
.act,.actsend{
  width: 160px;
  /* width:12.821%; */
  padding: 0;
}


/* .idsend{
  width: 3.564%;
  padding: 0;
}
.nopsend{
  width: 11.405%;
  padding: 0;
}
.lstupdtsend{
  width:11.69%;
  padding: 0;
}
.msgsend{
  width: 28.0829%;
  padding: 0;
}
.locsend {

  width: 11.405%;
padding: 0;

}
.reqtypsend{
  width: 11.405%;
  padding: 0;
}
.actsend{
  width:11.24525%;
  padding: 0;
}  */

/* .idreject{
  width: 5.146%;
  padding: 0;
}
.noreject{
  width: 14.253%;
  padding: 0;
}
.lstupdtreject{
  width:14.553%;
  padding: 0;
}
.msgreject{
  width: 39.26833%;
  padding: 0;
}
.locreject {

  width: 14.7465%;
padding: 0;

}
.reqtypreject{
  width: 14.7465%;
  padding: 0;
} */


  /* .request-table td {
   
    width: 100%;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    vertical-align: middle;
    height:64px;
   


    border-bottom: 1px solid #F5F5F5;
    padding: 10px;
    text-align: left;
    position:relative;
  } */
  
  
  
  .action-btn {
    background-color: #2f80ed;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right:10px;
    width: 100%;
    position:relative;
  }
  
  .action-btn:hover {
    background-color: #1e5fbf;
  }
 

  @media screen and (max-width:600px) {
    
    .segment{
      width: 100%;
    }
    
  }
  .request-page-cancel-button {
    width: 71px;       /* Fixed width */
    height: 28px;      /* Fixed height */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;         /* Gap between elements inside the button (if any) */
    background-color: rgba(26, 13, 171, 1); /* You can choose your color */
    border: none;
    border-radius: 4px; /* Slight rounding of the edges */
    
    cursor: pointer;
   
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
color:rgba(255, 255, 255, 1);

    
  }
  
  .cancel-button:hover {
    opacity: 1;        /* On hover, button will be fully visible */
  }
  

  .id-request-rejected{
    
    /* width: 50px; */
height: 64px;
padding: 12px 24px 12px 24px;
gap: 12px;
border: 0px 0px 1px 0px;


  }
  .name-request-rejected{

    display: flex;
    justify-content: center;
    img{
      width: 28px;
      height: 28px;
    }
    
    /* width: 160px;
    height: 44ppx;
    padding: 12px 24px 12px 0px;
    gap: 20px; */
    //border: 0px 0px 1px 0px;
    

  }

  .date-request-rejected{
    tect-align:center;
    /* width: 164px; */
height: 64px;
padding: 10px 10px 10px 4px;
gap: 12px;
border: 0px 0px 1px 0px;


  }

  .location-request-rejected{
    /* width: 160px; */
    height: 64px;
    padding: 12px 0px 12px 0px;
    gap: 12px;
    border: 0px 0px 1px 0px;
   
    
  }

  .requestType-request-rejected{
    /* width: 160px; */
    //height: 64px;
    padding: 12px 0px 12px 0px;
    gap: 12px;
    border: 0px 0px 1px 0px;
   
    
  }

  .message-request-rejected{
    /* width: 394px; */
    height: 64px;
    padding: 10px 10px 10px 4px;
    gap: 6px;
    border: 0px 0px 1px 0px  ;
    background:rgba(237, 243, 255, 1);
    //box-shadow:  0px 0px 1px 0px;
   
    
  }
  .msg-image-request-rejected{
    width: 48px;
height: 48px;
padding: 9px 10px 9px 10px;

border-radius:200px;
margin-right:37px;


  }
  .below-message-rejected{
  
font-size: 10px;
font-weight: 500;
line-height: 0px;
text-align: left;
margin-top:10px;
  }
  .below-message-container-rejected{
    
    display:flex;
    flex-direction:column;
    width:50%;
    align-items:flex-start;
    
  }



  .id-request-pending{
    
    /* width: 50px;
//height: 64px;
padding: 12px 24px 12px 24px;
gap: 12px;
border: 0px 0px 1px 0px; */


  }
  .name-request-pending{
    display: flex;
    justify-content: center;
    gap:15px;
    
    img{
      width: 28px;
      height: 28px;
      border-radius:50%;
      
    }

    
    //border-bottom: 1px solid rgba(245, 245, 245, 1);
  }

  /* .message-request{
   
    display: flex;
    justify-content: center;
    align-items: center;
    gap:37px;
    img{
      width: 48px;
      height: 48px;
      border-radius:50%;
      
    }
    border-bottom: 1px solid rgba(245, 245, 245, 1);
    background:rgba(237, 243, 255, 1);
    
  } */

  .message-request {
    display: flex;
    justify-content:center;
    align-items: center; /* Vertically centers both image and text */
    gap: 20px; /* Reduce the gap slightly for better alignment */
    //border-bottom: 1px solid rgba(245, 245, 245, 1);
    background: rgba(237, 243, 255, 1);
    padding: 10px 0; /* Ensure consistent padding */
   
}

.message-request img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    flex-shrink: 0; /* Prevent the image from shrinking */
}

.message-request-text {
    display: flex;
    width: 300px; 
    max-height: 32px;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal; 
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: Inter;
font-size: 13px;
font-weight: 500;

text-align: left;


    
}

.name-request-text{
  display: block;
  width: 100px; 
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
 
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}



  .date-request-pending{
    /* text-align:center;
    width: 164px;
//height: 64px;
padding: 10px 10px 10px 4px;
gap: 12px;
border: 0px 0px 1px 0px; */

font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 20px;

text-underline-position: from-font;
text-decoration-skip-ink: none;

border-bottom: 1px solid rgba(245, 245, 245, 1);
  }

  .location-request-pending{

    
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    /* width: 160px;
    //height: 64px;
    padding: 12px 0px 12px 0px;
    gap: 12px;
    border: 0px 0px 1px 0px; */
    border-bottom: 1px solid rgba(245, 245, 245, 1);
    
  }

  .requestType-request-pending{
    /* width: 160px;
    //height: 64px;
    padding: 12px 0px 12px 0px;
    gap: 12px;
    border: 0px 0px 1px 0px;
    */
   

    font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }

.requestType-request-name{
  font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 20px;
/* text-align: left; */
text-underline-position: from-font;
text-decoration-skip-ink: none;


}
  .message-request-pending{
    /* width: 394px;;
    //height: 64px;
    padding: 10px 10px 10px 4px;
    gap: 6px;
    border: 0px 0px 1px 0px  ;
    background:rgba(237, 243, 255, 1);
    //box-shadow:  0px 0px 1px 0px; */
   
    
  }
  .msg-image-request-pending{
    /* width: 48px;
height: 48px;
padding: 9px 10px 9px 10px;

border-radius:200px;
margin-right:37px; */


  }
  .below-message-pending{
  
font-size: 10px;
font-weight: 500;
line-height: 0px;
text-align: left;
margin-top:10px;
  }
  .below-message-container-pending{
    
    display:flex;
    flex-direction:column;
    width:50%;
    align-items:flex-start;
    
  }

  .action-button-request-pending{
    width: 160px;
  /* //height: 64px; */
  padding: 12px 24px 12px 24px;
  gap: 12px;
  border: 0px 0px 1px 0px;
 
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  
  
  }

  .request-page-cancel-button-pending {
   
   
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
color:rgba(255, 255, 255, 1);
gap:5px;
    
  }
  .request-page-accept-button-pending,
  .request-page-decline-button-pending {
      width: 74px;       /* Fixed width */
      height: 28px;      /* Fixed height */
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px;         /* Gap between elements inside the button (if any) */
      background-color: rgba(26, 13, 171, 1); /* Default background color */
      border: none;
      border-radius: 4px; /* Slight rounding of the edges */
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 1); /* Text color */
  }
  
  /* Specific background colors for Accept and Decline */
  .request-page-accept-button-pending,.request-page-decline-button-pending {
      background-color: rgba(26, 13, 171, 1); 
  }

  /* .message-request-text {
    display:flex;
    max-width: calc(1ch * 15); 
    white-space: normal; 
    word-wrap: break-word; 
    word-break: break-word; 
  } */
  .message-reject-text {
    display:flex;
    max-width: calc(1ch * 25); /* Limits the line to 15 characters wide */
    white-space: normal; /* Allows text to wrap */
    word-wrap: break-word; /* Breaks long words if needed */
    word-break: break-word; /* Ensures long words break properly */
  }



  
  .requestpage-profile-img{
    width: 28px;
   height: 28px;


  }